import React from 'react';
import './ProjectInfo.css';
import Menu from './utils/Menu';
import Figura01 from "./img/image_fx_17.jpg"
import Figura02 from "./img/image_fx_C11.jpg"
import Figura03 from "./img/image_fx_C19.jpg"
import Figura04 from "./img/image_fx_C12.jpg"


const ProjectInfo = () => {
  return (
    <div>
      <Menu />
      <div className="project-info">
                
        <h1 className="project-title">FaucetPool Initiative</h1>

        <img src={Figura01} alt="image" className="image" style={{ width: '45%', margin: '0 auto', display: 'block' }} />

        <p className="project-description">
          FaucetPool emerges as an innovative solution to reduce entry barriers for users into Web3, facilitating access to the crypto ecosystem and boosting technology adoption. The difficulty of interacting with digital wallets, smart contracts, and decentralized transactions prevents a large number of people from enjoying the benefits of Web3. With a simplified cryptocurrency distribution model, FaucetPool aims to attract new participants to the sector and significantly expand its user base.
        </p>
          
        <h3 className="project-title">Earning Opportunities</h3>

        <p className="project-details">
          FaucetPool's strategy is based on the traditional concept of Faucet sites, which distribute small amounts of cryptocurrency to encourage engagement. However, our project expands this approach with multiple forms of earning, including Faucet as NFT, Cryptocurrency Pool represented by NFTs, an exclusive site cryptocurrency, a franchise model via NFTs, and mining through NFTs. This diversified ecosystem allows users to have multiple ways to generate income within the platform.
        </p>
        
        <img src={Figura02} alt="image" className="image" style={{ width: '25%', margin: '0 auto', display: 'block' }} />
        
        <h3 className="project-title">Security Offering</h3>

        <p className="project-details">
          Security is one of FaucetPool's main pillars, ensuring reliability for users. The differential lies in the guaranteed buyback of NFTs by the site, following clear and transparent rules. The Faucet NFT, for example, has a guaranteed buyback after 365 days from its acquisition, while profits generated by the Liquidity Pool can be redeemed daily. Additionally, all buyback values are backed by USDT, bringing predictability and stability to investors.
        </p>

        <img src={Figura03} alt="image" className="image" style={{ width: '35%', margin: '0 auto', display: 'block' }} />

        <h3 className="project-title">Infrastructure Offering</h3>
        <p className="project-details">
          The project's infrastructure is hosted on cloud servers, using a robust technological stack to ensure scalability and security. The backend operates in an Ubuntu environment, with support for Node.js and NGINX to manage requests, while the frontend is developed in React, using Material UI to provide a fluid and intuitive experience. The system architecture allows efficient integration with smart contracts, ensuring transparent and secure execution of blockchain transactions.
        </p>

        <img src={Figura04} alt="image" className="image" style={{ width: '45%', margin: '0 auto', display: 'block' }} />

        <h3 className="project-title">The Guarantee</h3>

        <p className="project-details">
          The "Pagadora.sol" smart contract is one of the central elements of FaucetPool, designed to manage the distribution of ERC20 tokens to holders of ERC-1155 NFTs. It implements a quota system that defines the number of tokens to be received according to the number of NFTs owned. The contract includes functions such as owner registration, dynamic quota calculation, automatic reward distribution, and security guarantee. The code was structured to optimize gas consumption, ensuring efficiency and low operational costs for users. In this way, FaucetPool positions itself as an innovative and accessible model for the entry of new participants in the Web3 world.
        </p>
      </div>
    </div>
  );
};

export default ProjectInfo;
// src/utils/Menu.js
import React, { useState } from 'react';
import { AppBar, Toolbar, Button, Menu as MuiMenu, MenuItem, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from '../img/Logo_01.jpg';
import promotionImage from '../img/promotion.png';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const Menu = () => {
  const navigate = useNavigate();
  const [officerAnchorEl, setOfficerAnchorEl] = useState(null);
  
  const handleOfficerMenuOpen = (event) => {
    setOfficerAnchorEl(event.currentTarget);
  };

  const handleOfficerMenuClose = () => {
    setOfficerAnchorEl(null);
  };

  const navigateAndClose = (path) => {
    navigate(path);
    handleOfficerMenuClose();
  };
  
  const handleBuyNFT = () => {
    window.open('https://opensea.io/collection/faucetpool', '_blank');
  };

  return (
    <>
      {/* Imagem de promoção fixa na tela */}
      <img 
        src={promotionImage} 
        alt="Promotion" 
        style={{
          position: 'fixed',
          height: '200px',
          left: '120px',
          top: '100px',
          borderRadius: '12px',
          boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
          cursor: 'pointer',
          zIndex: 1000
        }}
        onClick={() => window.open('https://opensea.io/assets/matic/0xb1e65d5db800880b8c81dcfed3770454f3092fcc/1', '_blank')}
      />

      <AppBar position="static" style={{ backgroundColor: '#333' }}>
        <Toolbar style={{ display: 'flex', alignItems: 'center' }}>
          {/* Logo à esquerda */}
          <img src={logo} alt="Logo" className="logo" />

          {/* Espaço flexível que empurra o botão para a direita */}
          <Box sx={{ flexGrow: 1 }} />

          {/* Botão Buy NFT posicionado à direita */}
          <Button
            variant="contained"
            startIcon={<ShoppingCartIcon />}
            onClick={handleBuyNFT}
            style={{
              margin: '8px 16px',
              background: 'linear-gradient(45deg, #ff9800 30%, #ff5722 90%)',
              color: 'white',
              fontWeight: 'bold',
              borderRadius: '20px',
              boxShadow: '0 3px 5px rgba(255, 87, 34, 0.3)',
              padding: '8px 16px',
              zIndex: 10
            }}
          >
            Buy NFT
          </Button>
        </Toolbar>
        
        {/* Toolbar com os botões de navegação */}
        <Toolbar style={{ display: 'flex', justifyContent: 'center' }}>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Button color="inherit" onClick={() => navigate('/')}>
              Home
            </Button>
            <Button 
              color="inherit" 
              onClick={handleOfficerMenuOpen}
              aria-controls="officer-menu"
              aria-haspopup="true"
            >
              Officer
            </Button>
            <Button color="inherit" onClick={() => navigate('/nft-01')}>
              NFT Info.
            </Button>
            <Button color="inherit" onClick={() => navigate('/loteria')}>
              Lottery
            </Button>
            <Button color="inherit" onClick={() => navigate('/dashboard')}>
              Dashboard
            </Button>
          </Box>
          
          <MuiMenu
            id="officer-menu"
            anchorEl={officerAnchorEl}
            keepMounted
            open={Boolean(officerAnchorEl)}
            onClose={handleOfficerMenuClose}
          >
            <MenuItem onClick={() => navigateAndClose('/project-info')}>
              Project Information
            </MenuItem>
            <MenuItem onClick={() => navigateAndClose('/privacy-policy')}>
              Privacy Policy
            </MenuItem>
            <MenuItem onClick={() => navigateAndClose('/terms-of-service')}>
              Terms of Service
            </MenuItem>
            <MenuItem onClick={() => navigateAndClose('/Faq')}>
              Frequently Asked Questions (FAQ)
            </MenuItem>
            <MenuItem onClick={() => navigateAndClose('/RoadmapPage')}>
              Roadmap Page
            </MenuItem> 
          </MuiMenu>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Menu;
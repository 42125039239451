import React, { useEffect, useState } from 'react';
import Menu from './utils/Menu';
import { useNavigate } from 'react-router-dom';
import { calculateArea } from './utils/calculateArea';
import { Button, Container, Box } from '@mui/material';
import PromoBanner from './utils/Banner01';

import image1 from './img/image_fx_17.png';
import image2 from './img/image_fx_11.png';
import image3 from './img/image_fx_12.png';

import sectionImage1 from './img/image_fx_17.png';
import sectionImage2 from './img/image_fx_C26.png';
import celo from "./img/season_celo.png";
import './OpeningPage.css';

const OpeningPage = () => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0, area: 0 });
  const [currentImage, setCurrentImage] = useState(0);
  const [fade, setFade] = useState(true);
  const images = [image1, image2, image3];
  const texts = [
    "Earn daily rewards simply by owning our NFT!",
    "Own the future: NFTs with real utility and constant earnings!", 
    "The more NFTs, the more tokens – maximize your earnings!"
  ];
  const navigate = useNavigate();

  useEffect(() => {
    const updateDimensions = () => {
      const { width, height, area } = calculateArea();
      setDimensions({ width, height, area });
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentImage((prevImage) => (prevImage + 1) % images.length);
        setFade(true);
      }, 1000); // Reduced to 1 second for faster transition
    }, 6000); // Increased to 6 seconds to give more reading time

    return () => clearInterval(interval);
  }, [images.length]);

  const handleSectionImage1Click = () => {
    navigate('/project-info');
  };

  const navigateToDashboard = () => {
    navigate('/dashboard');
  };
  const navigateToLotery = () => {
    navigate('/Loteria');
  };

  return (
    <div className="opening-page" style={{ backgroundColor: '#0a3dca' }}>
      <Menu />

      {/* Adicione o banner promocional aqui */}
      <PromoBanner />

      <div 
        style={{
          position: 'fixed',
          top: '20px',        // Alterado de 'bottom' para 'top'
          right: '20px',
          zIndex: 1000,
          opacity: 0.8,
          pointerEvents: 'none'
        }}
      >
        <img 
          src={celo} 
          alt="Official Seal" 
          width="200" 
          height="200"
        />
      </div>
      
      <Container maxWidth="xl" className="hero-container">
        <h1 className="welcome-text"> </h1>
        <h2 className="welcome-subtext">Your NFT, your passive income!</h2>
        
        <div className={`image-container ${fade ? 'fade-in' : 'fade-out'}`}>
          <img 
            src={images[currentImage]} 
            alt="NFT FaucetPool" 
            className="hero-image" 
          />
          <div className="image-text-container">
            <p className="image-text">{texts[currentImage]}</p>
            <Button 
              variant="contained" 
              size="large"
              className="cta-button"
              onClick={navigateToDashboard}
            >
              Access Dashboard
            </Button>
          </div>
        </div>
      </Container>      

      <div className="section-container">
        <div className="section" onClick={handleSectionImage1Click}>
          <div className="section-content">
            <img src={sectionImage1} alt="NFTs with real utility" className="section-image" />
            <div className="section-text">
              <h2>NFTs with real utility and constant earnings</h2>
              <p>In FaucetPool, your NFTs go beyond simple digital collection. Each NFT you own guarantees participation in an intelligent token distribution system, generating recurring earnings automatically.</p>
              <p>It's not just a digital asset, but a true source of passive income, driven by a transparent and decentralized mechanism. 🚀</p>
              <Button 
                variant="outlined" 
                className="learn-more-button"
                onClick={handleSectionImage1Click}
              >
                Learn more
              </Button>
            </div>
          </div>
        </div>

        <div className="section">
          <div className="section-content reverse">
            <img src={sectionImage2} alt="Free lottery" className="section-image" />
            <div className="section-text">
              <h2>Play for free and compete for big prizes!</h2>
              <p>🎉 Luck is on your side – and best of all, at no cost! 🎉</p>
              <p>In FaucetPool, you enter the competition for big prizes at no cost! Just participate in our free lottery and let chance work in your favor.</p>
              <p>There's no risk, only opportunities to win! 🍀</p>
              <Button 
                variant="outlined" 
                className="learn-more-button"
                onClick={navigateToLotery}
              >
                Participate Now
              </Button>
            </div>
          </div>
        </div>
      </div>
      
      {/* Footer removed from here - now using the global footer */}
    </div>
  );
};

export default OpeningPage;
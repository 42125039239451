import React, { useState, useEffect } from 'react';
import '../OpeningPage.css';
import Menu from '../utils/Menu';
import imagem_NFT01 from '../img/image_fx_11.png';

const Nft_01 = () => {
  const [isVisible, setIsVisible] = useState(false);
  
  // URL do NFT no OpenSea
  const nftPurchaseUrl = "https://opensea.io/assets/matic/0xb1e65d5db800880b8c81dcfed3770454f3092fcc/1";

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 300);
    return () => clearTimeout(timer);
  }, []);

  // Função para redirecionar para a página de compra
  const handlePurchaseClick = () => {
    window.open(nftPurchaseUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="nft-page"> 
      <Menu />
    
      <div className={`nft-showcase ${isVisible ? 'fade-in' : ''}`}>
        <div className="nft-container">
          <div className="nft-highlight-top"></div>
          
          <div className="nft-content">
            <div className="nft-header">
              <h1 className="nft-title">Cloud Miner - The First NFT of Season 01</h1>
              <div className="nft-badge">LIMITED EDITION</div>
            </div>
            
            <div className="nft-display">
              <div 
                className="image-container pulse-slow"
                onClick={handlePurchaseClick}
                style={{ cursor: 'pointer' }}
              >
                <img src={imagem_NFT01} alt="Cloud Miner NFT" className="nft-image" />
                <div className="image-glow"></div>
                <div className="image-overlay">
                  <div className="click-to-buy">Click to Buy on OpenSea</div>
                </div>
              </div>
              
              <div className="nft-description">
                <div className="announcement">
                  🚀 <strong>THE FIRST NFT OF SEASON 01 IS HERE!</strong> 🚀
                </div>
                
                <p className="intro-text">
                  Introducing <strong>"Cloud Miner"</strong>, the pioneer NFT of FaucetPool! 🌐💎 A unique piece that is not just a collectible but a true <strong>daily income generator</strong> in our <strong>Cryptocurrency Pool</strong>.
                </p>
                
                <div className="benefits-section">
                  <h2 className="benefits-title">💰 Why get the Cloud Miner?</h2>
                  <div className="benefits-list">
                    <div className="benefit-item">
                      <div className="benefit-icon">💸</div>
                      <div className="benefit-text">
                        <strong>Guaranteed daily earnings</strong> in the Liquidity Pool
                      </div>
                    </div>
                    
                    <div className="benefit-item">
                      <div className="benefit-icon">🔄</div>
                      <div className="benefit-text">
                        <strong>Guaranteed buyback</strong> by FaucetPool after the buyback period
                      </div>
                    </div>
                    
                    <div className="benefit-item">
                      <div className="benefit-icon">🚀</div>
                      <div className="benefit-text">
                        <strong>Exclusive access</strong> to future phases of the project
                      </div>
                    </div>
                    
                    <div className="benefit-item">
                      <div className="benefit-icon">🔥</div>
                      <div className="benefit-text">
                        <strong>Mine the website's Cryptocurrency</strong> and increase your share in the Pool
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="cta-section">
                  <p className="highlight-text">
                    🔥 <strong>This is your chance to enter Web3 in a simple, secure, and profitable way!</strong>
                  </p>
                  
                  <p className="urgency-text">
                    The supply is <strong>LIMITED</strong>! Don't miss this opportunity to secure your participation before the next drop.
                  </p>
                  
                  <button 
                    className="cta-button pulse-animation"
                    onClick={handlePurchaseClick}
                  >
                    Get your Cloud Miner now! 🚀
                  </button>
                  
                  <div className="start-today">
                    <strong>Start earning TODAY!</strong> 💎
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="nft-highlight-bottom"></div>
        </div>
      </div>
      
      <style jsx>{`
        .nft-page {
          background: linear-gradient(135deg, #0a1930, #1e3a8a);
          min-height: 100vh;
          padding-bottom: 60px;
        }
        
        .nft-showcase {
          max-width: 1200px;
          margin: 40px auto;
          padding: 20px;
          opacity: 0;
          transform: translateY(30px);
          transition: opacity 1s ease, transform 1s ease;
        }
        
        .fade-in {
          opacity: 1;
          transform: translateY(0);
        }
        
        .nft-container {
          position: relative;
          background: linear-gradient(135deg, #1e40af, #3b82f6);
          border-radius: 20px;
          overflow: hidden;
          box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
          border: 1px solid rgba(255, 255, 255, 0.2);
        }
        
        .nft-highlight-top, .nft-highlight-bottom {
          position: absolute;
          height: 4px;
          width: 100%;
          background: linear-gradient(90deg, transparent, #fcd34d, transparent);
          animation: shimmer 6s infinite linear;
        }
        
        .nft-highlight-top {
          top: 0;
        }
        
        .nft-highlight-bottom {
          bottom: 0;
          animation-delay: 3s;
        }
        
        .nft-content {
          padding: 30px;
          color: white;
        }
        
        .nft-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 30px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          padding-bottom: 20px;
          flex-wrap: wrap;
        }
        
        .nft-title {
          font-size: 2.5rem;
          font-weight: 800;
          background: linear-gradient(90deg, #ffffff, #fcd34d);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
          margin: 0;
        }
        
        .nft-badge {
          background: linear-gradient(90deg, #ef4444, #f97316);
          padding: 8px 16px;
          border-radius: 50px;
          font-weight: bold;
          font-size: 0.9rem;
          letter-spacing: 1px;
          animation: pulse 2s infinite;
        }
        
        .nft-display {
          display: flex;
          gap: 40px;
          align-items: flex-start;
        }
        
        .image-container {
          position: relative;
          flex: 1;
          max-width: 50%;
          border-radius: 16px;
          box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
          overflow: hidden;
        }
        
        .nft-image {
          width: 100%;
          display: block;
          transition: transform 0.5s ease;
        }
        
        .image-container:hover .nft-image {
          transform: scale(1.03);
        }
        
        .image-container:hover .image-overlay {
          opacity: 1;
        }
        
        .image-overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0;
          transition: opacity 0.3s ease;
        }
        
        .click-to-buy {
          background: #f59e0b;
          color: white;
          font-weight: bold;
          padding: 12px 24px;
          border-radius: 50px;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
        }
        
        .image-glow {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: radial-gradient(circle at center, rgba(252, 211, 77, 0.2), transparent);
          pointer-events: none;
        }
        
        .nft-description {
          flex: 1;
        }
        
        .announcement {
          font-size: 1.4rem;
          font-weight: bold;
          margin-bottom: 20px;
          padding: 10px;
          background: rgba(0, 0, 0, 0.2);
          border-radius: 8px;
          text-align: center;
        }
        
        .intro-text {
          font-size: 1.1rem;
          line-height: 1.6;
          margin-bottom: 30px;
        }
        
        .benefits-section {
          background: rgba(0, 0, 0, 0.2);
          border-radius: 12px;
          padding: 20px;
          margin-bottom: 30px;
        }
        
        .benefits-title {
          margin-top: 0;
          font-size: 1.6rem;
          margin-bottom: 20px;
        }
        
        .benefits-list {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 20px;
        }
        
        .benefit-item {
          display: flex;
          align-items: center;
          background: rgba(255, 255, 255, 0.1);
          border-radius: 8px;
          padding: 15px;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
        }
        
        .benefit-item:hover {
          transform: translateY(-5px);
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
        }
        
        .benefit-icon {
          font-size: 2rem;
          margin-right: 15px;
        }
        
        .benefit-text {
          font-size: 1rem;
        }
        
        .cta-section {
          text-align: center;
          margin-top: 40px;
        }
        
        .highlight-text {
          font-size: 1.2rem;
          background: rgba(0, 0, 0, 0.2);
          padding: 15px;
          border-radius: 8px;
          margin-bottom: 20px;
        }
        
        .urgency-text {
          font-size: 1.1rem;
          color: #fcd34d;
          margin-bottom: 30px;
        }
        
        .cta-button {
          background: linear-gradient(90deg, #f59e0b, #f97316);
          color: white;
          font-size: 1.3rem;
          font-weight: bold;
          padding: 15px 40px;
          border: none;
          border-radius: 50px;
          cursor: pointer;
          transition: all 0.3s ease;
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
          margin-bottom: 20px;
        }
        
        .cta-button:hover {
          transform: translateY(-3px);
          box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
        }
        
        .start-today {
          font-size: 1.2rem;
          color: #fcd34d;
        }
        
        .pulse-animation {
          animation: pulse 2s infinite;
        }
        
        .pulse-slow {
          animation: pulse 4s infinite;
        }
        
        @keyframes pulse {
          0% {
            box-shadow: 0 0 0 0 rgba(252, 211, 77, 0.7);
          }
          70% {
            box-shadow: 0 0 0 15px rgba(252, 211, 77, 0);
          }
          100% {
            box-shadow: 0 0 0 0 rgba(252, 211, 77, 0);
          }
        }
        
        @keyframes shimmer {
          0% {
            transform: translateX(-100%);
          }
          100% {
            transform: translateX(100%);
          }
        }
        
        @media (max-width: 900px) {
          .nft-display {
            flex-direction: column;
          }
          
          .image-container {
            max-width: 100%;
            margin-bottom: 30px;
          }
          
          .benefits-list {
            grid-template-columns: 1fr;
          }
          
          .nft-title {
            font-size: 2rem;
            margin-bottom: 15px;
          }
          
          .nft-header {
            flex-direction: column;
            align-items: flex-start;
          }
        }
      `}</style>
    </div>
  );
};

export default Nft_01;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const PromoBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();

  // Efeito para animar a entrada do banner
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const handleNftButtonClick = () => {
    navigate('/nft-01'); // Ajuste para o caminho correto da sua loja de NFT
  };

  return (
    <div className={`banner-wrapper ${isVisible ? 'banner-visible' : ''}`}>
      <div className="banner-container">
        <div className="banner-highlight-top"></div>
        <div className="banner-content">
          <h1 className="banner-title">💰 Earn in 3 Powerful Ways with FaucetPool! 🚀</h1>
          
          <div className="banner-features">
            <div className="feature-card">
              <img src="images/PromotionSale.png" alt="Promoção de Venda" className="feature-icon-img" />
              <h3>Daily Passive Rewards</h3>
              <p>35% of the project's daily earnings are reserved for NFT holders! The more NFTs you have, the bigger your share!</p>
            </div>
            
            <div className="feature-card">
            <img src="images/loteria.png" alt="Lotery" className="feature-icon-img" />
              <h3>Free Bitcoin Lottery</h3>
              <p>Participate with a chance to win BTC <span className="highlight">without spending a penny</span>! Draws happen regularly!</p>
            </div>
            
            <div className="feature-card">
            <img src="images/poolBonus.png" alt="pools" className="feature-icon-img" />
              <h3>Liquidity Pool Bonus</h3>
              <p>Your NFTs give access to extra rewards through our staking system, increasing your earnings even more!</p>
            </div>
          </div>

          <div className="banner-benefits">
            <p>✅ No costs, no risks – pure rewards only!</p>
            <p>✅ Transparent and automated payments on the blockchain!</p>
            <p>✅ The future of decentralized earnings is here!</p>
          </div>

          <button 
            className="banner-button pulse-animation"
            onClick={handleNftButtonClick}
          >
            👉 Get your NFT today and start earning instantly! 🔥
          </button>
        </div>
        <div className="banner-highlight-bottom"></div>
      </div>
      
      <style jsx>{`
        .banner-wrapper {
          padding: 20px;
          margin: 40px auto;
          opacity: 0;
          transform: translateY(30px);
          transition: opacity 0.8s ease, transform 0.8s ease;
          max-width: 1200px;
        }
        
        .banner-visible {
          opacity: 1;
          transform: translateY(0);
        }
        
        .banner-container {
          position: relative;
          background: linear-gradient(135deg, #1a56db, #7e3af2);
          border-radius: 16px;
          box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
          overflow: hidden;
          border: 1px solid rgba(255, 255, 255, 0.2);
        }
        
        .banner-highlight-top, .banner-highlight-bottom {
          position: absolute;
          height: 3px;
          width: 100%;
          background: linear-gradient(90deg, transparent, #ffec3d, transparent);
          animation: shimmer 4s infinite linear;
        }
        
        .banner-highlight-top {
          top: 0;
        }
        
        .banner-highlight-bottom {
          bottom: 0;
          animation-delay: 2s;
        }
        
        .banner-content {
          padding: 30px;
          color: white;
          text-align: center;
        }
        
        .banner-title {
          font-size: 2.5rem;
          font-weight: bold;
          margin-bottom: 1.5rem;
          text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
        }
        
        .banner-features {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          gap: 20px;
          margin-bottom: 30px;
        }
        
        .feature-card {
          flex: 1;
          min-width: 250px;
          background: rgba(255, 255, 255, 0.1);
          border-radius: 12px;
          padding: 20px;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
        }
        
        .feature-card:hover {
          transform: translateY(-5px);
          box-shadow: 0 7px 15px rgba(0, 0, 0, 0.2);
        }
        
        .feature-icon {
          font-size: 2rem;
          margin-bottom: 10px;
        }
        
        .feature-icon-img {
          width: 348px;
          height: 348px;
          object-fit: contain;
          margin-bottom: 10px;
        }
        
        .feature-card h3 {
          font-size: 1.2rem;
          font-weight: bold;
          margin-bottom: 10px;
        }
        
        .highlight {
          background: rgba(255, 236, 61, 0.2);
          color: #ffec3d;
          padding: 2px 5px;
          border-radius: 4px;
          font-weight: bold;
        }
        
        .banner-benefits {
          font-size: 1.1rem;
          font-weight: 500;
          margin: 25px 0;
          line-height: 1.8;
        }
        
        .banner-button {
          background: #ffec3d;
          color: #1a202c;
          font-weight: bold;
          font-size: 1.2rem;
          padding: 15px 30px;
          border-radius: 50px;
          border: none;
          cursor: pointer;
          transition: all 0.3s ease;
          margin-top: 15px;
          box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
        }
        
        .banner-button:hover {
          background: #ffd60a;
          transform: translateY(-2px);
          box-shadow: 0 6px 20px rgba(0, 0, 0, 0.25);
        }
        
        .pulse-animation {
          animation: pulse 2s infinite;
        }
        
        @keyframes pulse {
          0% {
            box-shadow: 0 0 0 0 rgba(255, 236, 61, 0.7);
          }
          70% {
            box-shadow: 0 0 0 15px rgba(255, 236, 61, 0);
          }
          100% {
            box-shadow: 0 0 0 0 rgba(255, 236, 61, 0);
          }
        }
        
        @keyframes shimmer {
          0% {
            transform: translateX(-100%);
          }
          100% {
            transform: translateX(100%);
          }
        }
        
        @media (max-width: 768px) {
          .banner-features {
            flex-direction: column;
          }
          
          .banner-title {
            font-size: 1.8rem;
          }
          
          .banner-content {
            padding: 20px;
          }
        }
      `}</style>
    </div>
  );
};

export default PromoBanner;

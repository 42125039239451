import React from 'react';
import './OpeningPage.css'; // Uses the same styles as OpeningPage
import Menu from './utils/Menu';

const PrivacyPolicy = () => {
  return (
    <div className="opening-page">
      <Menu />
      
      <div className="content">
        <h1>Privacy Policy</h1>
        <p>
          FaucetPool values the security and privacy of its users. This Privacy Policy describes how we collect, use, and protect the information of users who interact with our platform.
        </p>
        
        <h2>Information Collected</h2>
        <p>
          FaucetPool only collects essential information for the operation of the platform:
        </p>
        <ul>
          <li><strong>Digital wallet address:</strong> Used to process transactions and verify eligibility for participation in cryptocurrency distributions.</li>
          <li><strong>Ownership of FaucetPool NFTs:</strong> Used to determine the benefits and rewards available to each user.</li>
        </ul>
        
        <h2>Use of Information</h2>
        <p>
          The collected information is used exclusively to:
        </p>
        <ul>
          <li>Identify users eligible for cryptocurrency distributions.</li>
          <li>Manage the granting of rewards and payments within the platform.</li>
          <li>Ensure the correct execution of smart contracts.</li>
        </ul>
        
        <h2>Data Sharing</h2>
        <p>
          FaucetPool <strong>does not share, sell, or trade</strong> any user information with third parties. All transactions and interactions occur directly on the blockchain, ensuring transparency and security without the need for intermediaries.
        </p>
        
        <h2>Security</h2>
        <p>
          We adopt best practices to protect user data and ensure that transactions occur securely. As all interaction occurs via blockchain, security is reinforced by the decentralization and transparency of the technology.
        </p>
        
        <h2>User Rights</h2>
        <p>
          Users have full control over their digital wallets and NFTs. As we do not retain personal information, there is no need to request deletion of data or adjustments.
        </p>
        
        <h2>Changes to the Privacy Policy</h2>
        <p>
          We may update this Privacy Policy as needed. We will notify users of relevant changes through the official website.
        </p>
        
        <h2>Contact</h2>
        <p>
          If you have questions about this Privacy Policy, users can contact us through our official channel on the FaucetPool website.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

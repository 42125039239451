import React, { useState, useEffect, useRef } from 'react';
import { 
  Container, Grid, Paper, Typography, Button, List, ListItem, 
  ListItemText, Box, Chip, Divider, ThemeProvider, createTheme,
  Card, CardContent, CardActionArea, Avatar, CircularProgress
} from '@mui/material';
import { connectMetaMask } from "./utils/connectMetaMask";
import { ethers } from 'ethers';
import Menu from './utils/Menu';
import 'util';
// Importar WalletConnect
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

// Tema personalizado para melhorar a aparência
const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#f5f5f5',
      paper: '#ffffff',
    },
    text: {
      primary: '#333333',
      secondary: '#666666',
    },
    action: {
      active: '#3f51b5',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 500,
    },
    button: {
      textTransform: 'none',
      fontWeight: 500,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          padding: '8px 24px',
        },
        containedPrimary: {
          background: 'linear-gradient(45deg, #3f51b5 30%, #5c6bc0 90%)',
        },
        containedSecondary: {
          background: 'linear-gradient(45deg, #f50057 30%, #ff4081 90%)',
        },
      },
    },
  },
});

// Atualizar para o novo endereço do contrato com função payOutAndUnstake
const contractAddress = "0xb09689DF046775Be7E7f89fAc6B004a279323cCc"; // Novo endereço do contrato

// Atualizar a ABI para incluir a nova função
const contractABI = [
  "function payOut() public",
  "function registerOwnerData() public",
  "function stakeNFTs(uint256 nftId, uint256 amount) public",
  "function unstakeNFTs() public",
  "function payOutAndUnstake() public" // Nova função combinada
];

// Adicione estas constantes logo após a declaração de contractABI
const nftContractAddress = "0xb1E65d5dB800880b8C81DcfED3770454F3092FcC"; // Endereço do NFT ERC1155
const nftABI = [
  "function setApprovalForAll(address operator, bool approved) external",
  "function isApprovedForAll(address account, address operator) external view returns (bool)",
  "function balanceOf(address account, uint256 id) external view returns (uint256)"
];

// Detalhes da rede Polygon
const POLYGON_CHAIN_ID = '0x89'; // 137 em hexadecimal
const MUMBAI_CHAIN_ID = '0x13881'; // 80001 em hexadecimal (testnet)

// Verificar e solicitar mudança para a rede correta
async function checkAndSwitchNetwork() {
  try {
    // Verificar a rede atual
    const chainId = await window.ethereum.request({ method: 'eth_chainId' });
    
    // Se já estiver na rede Polygon, não faz nada
    if (chainId === POLYGON_CHAIN_ID || chainId === MUMBAI_CHAIN_ID) {
      console.log('Already connected to Polygon network');
      return true;
    }
    
    // Tenta mudar para a rede Polygon
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: POLYGON_CHAIN_ID }], // Polygon Mainnet
      });
      console.log('Successfully switched to Polygon network');
      return true;
    } catch (switchError) {
      // Código 4902 significa que a rede não está adicionada à MetaMask
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: POLYGON_CHAIN_ID,
                chainName: 'Polygon Mainnet',
                nativeCurrency: {
                  name: 'MATIC',
                  symbol: 'MATIC',
                  decimals: 18
                },
                rpcUrls: ['https://polygon-rpc.com/'],
                blockExplorerUrls: ['https://polygonscan.com/']
              }
            ],
          });
          console.log('Polygon network successfully added');
          return true;
        } catch (addError) {
          console.error('Erro ao adicionar rede Polygon:', addError);
          return false;
        }
      }
      console.error('Erro ao mudar para rede Polygon:', switchError);
      return false;
    }
  } catch (error) {
    console.error('Erro ao verificar a rede:', error);
    return false;
  }
}

// Função para tornar mensagens de erro mais amigáveis
const formatErrorMessage = (error) => {
  // Capturar a mensagem do erro
  const errorMessage = error.message || "Erro desconhecido";
  
  // Verificar erros comuns de carteira
  if (errorMessage.includes("user rejected transaction")) {
    return "You cancelled the transaction.";
  }
  
  if (errorMessage.includes("insufficient funds")) {
    return "Insufficient balance to perform this operation. Make sure you have ETH to pay for gas.";
  }
  
  // Erros do contrato - extrair a mensagem de revert
  if (errorMessage.includes("execution reverted")) {
    // Erros específicos do contrato Pagadora
    if (errorMessage.includes("Aguarde 24 horas")) {
      return "You need to wait 24 hours between operations.";
    }
    if (errorMessage.includes("Voce nao tem NFTs em stake")) {
      return "You don't have any staked NFTs.";
    }
    if (errorMessage.includes("Voce ja tem NFTs em stake")) {
      return "You already have staked NFTs. Unstake them first.";
    }
    if (errorMessage.includes("Voce tem cotas pendentes")) {
      return "You have pending quotas. Withdraw them before unstaking.";
    }
    if (errorMessage.includes("Voce nao tem cotas")) {
      return "You don't have any quotas to withdraw.";
    }
    if (errorMessage.includes("Saldo insuficiente no contrato")) {
      return "The contract does not have sufficient balance for payment at the moment.";
    }
    
    // Tenta extrair a mensagem de erro do revert
    const revertReason = errorMessage.match(/reason="([^"]+)"/);
    if (revertReason && revertReason[1]) {
      return `Error in contract: ${revertReason[1]}`;
    }
  }
  
  // Retornar mensagem original para outros casos
  return `Error: ${errorMessage}`;
};

const Dashboard = () => {
  const [connected, setConnected] = useState(false);
  const [hasToken, setHasToken] = useState(null); // Estado: null antes da verificação do token
  const [dashboardCards, setDashboardCards] = useState([]);
  const [treasuryHistory, setTreasuryHistory] = useState([]); // Histórico da tesouraria
  // Alteramos o estado para controlar três ações: solicitar pagamento, processar pagamento e transferência.
  const [actionType, setActionType] = useState('solicitar');
  const [web3Modal, setWeb3Modal] = useState(null);
  const [provider, setProvider] = useState(null);

  // Campos para solicitar pagamento:
  const [nftId, setNftId] = useState('');

  // Adicione esta linha para detectar dispositivos móveis
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  // Atualize a função loadTreasuryHistory para enviar o endereço do usuário
  const loadTreasuryHistory = async () => {
    try {
      // Obtém o endereço do usuário conectado
      const accounts = await window.ethereum.request({ method: 'eth_accounts' });
      const userAddress = accounts[0];
      
      // Envia o endereço como parâmetro de consulta
      const response = await fetch(`/api/treasury-history?userAddress=${userAddress}`);
      
      if (!response.ok) {
        throw new Error(`Erro na resposta do servidor: ${response.statusText}`);
      }
      const data = await response.json();
      setTreasuryHistory(data.history);
      console.log('Histórico carregado para:', data.userAddress);
    } catch (error) {
      console.error('Erro ao carregar o histórico da tesouraria:', error);
    }
  };

  // Adicione esta função no componente Dashboard
  const loadDashboardCards = async () => {
    try {
      // Obtém o endereço do usuário conectado
      const accounts = await window.ethereum.request({ method: 'eth_accounts' });
      const userAddress = accounts[0];
      
      console.log('Carregando cards para:', userAddress);
      
      const response = await fetch('/api/create-dashboard-card', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userAddress })
      });
      
      if (!response.ok) {
        throw new Error(`Erro na resposta do servidor: ${response.statusText}`);
      }
      
      const data = await response.json();
      console.log('Cards recebidos:', data);
      
      if (data.cards) {
        setDashboardCards(data.cards);
      } else {
        console.warn('Resposta não contém cards:', data);
      }
    } catch (error) {
      console.error('Erro ao carregar os cards:', error);
    }
  };

  // Inicializar o Web3Modal
  useEffect(() => {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          rpc: {
            137: 'https://polygon-rpc.com/',
            80001: 'https://rpc-mumbai.maticvigil.com/'
          }
        }
      }
    };
    
    const newWeb3Modal = new Web3Modal({
      network: "polygon", // opcional
      cacheProvider: true,
      providerOptions
    });
    
    setWeb3Modal(newWeb3Modal);
  }, []);

  const handleConnect = async () => {
    try {
      // Se estiver no mobile, usamos preferencialmente WalletConnect
      if (isMobile) {
        if (web3Modal) {
          const modalProvider = await web3Modal.connect();
          const ethersProvider = new ethers.providers.Web3Provider(modalProvider);
          
          setProvider(ethersProvider);
          const accounts = await ethersProvider.listAccounts();
          
          if (accounts.length > 0) {
            setConnected(true);
            window.ethereum = modalProvider; // Para compatibilidade com o código existente
            await setupAfterConnection();
          }
        }
      } else {
        // Fluxo original para desktop
        const success = await connectMetaMask();
        setConnected(success);
    
        if (success) {
          await setupAfterConnection();
        }
      }
    } catch (error) {
      console.error("Erro ao conectar carteira:", error);
      alert("Houve um erro ao conectar sua carteira. Por favor, tente novamente.");
    }
  };

  // Função simplificada para conectar com WalletConnect
  const connectWithWalletConnect = async () => {
    try {
      const provider = new WalletConnectProvider({
        rpc: {
          137: 'https://polygon-rpc.com/',
          80001: 'https://rpc-mumbai.maticvigil.com/'
        }
      });
      
      await provider.enable();
      const web3Provider = new ethers.providers.Web3Provider(provider);
      const accounts = await web3Provider.listAccounts();
      
      if (accounts.length > 0) {
        window.ethereum = provider; // Para compatibilidade
        return true;
      }
      return false;
    } catch (error) {
      console.error("Erro ao conectar com WalletConnect:", error);
      return false;
    }
  };

  // Função auxiliar para configurações após conexão
  const setupAfterConnection = async () => {
    // Verificar e mudar para a rede Polygon se necessário
    const isCorrectNetwork = await checkAndSwitchNetwork();
    if (!isCorrectNetwork) {
      alert('Please switch to the Polygon network to continue using the application.');
      return;
    }
  
    // Obtém o endereço do usuário
    const accounts = await window.ethereum.request({ method: 'eth_accounts' });
    const userAddress = accounts[0];
    console.log(`MetaMask conectada: ${userAddress}`);
  
    // Carrega os cards do dashboard
    await loadDashboardCards();
    
    // Carrega o histórico da tesouraria
    await loadTreasuryHistory();
  };

  const registrarTransacao = async (txHash, descricao) => {
    const accounts = await window.ethereum.request({ method: 'eth_accounts' });
    const userAddress = accounts[0];
    
    await fetch('/api/registrar-transacao', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        userAddress,
        txHash,
        descricao,
        date: new Date().toLocaleString()
      })
    });
    
    // Recarrega o histórico após registrar
    await loadTreasuryHistory();
  };

  // Substitua sua função handleSolicitar por esta versão
const handleSolicitar = async () => {
  // Verificar rede antes de executar a ação
  const isCorrectNetwork = await checkAndSwitchNetwork();
  if (!isCorrectNetwork) {
    alert('Please switch to the Polygon network to continue using the application.');
    return;
  }

  // Usar nftId fixo "1" e solicitar apenas a quantidade via prompt
  const nftId = 1; // ID fixo agora
  const amount = prompt('Enter the amount of NFTs to stake:');
  
  if (!amount || isNaN(amount)) {
    alert('Please provide a valid amount.');
    return;
  }

  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    
    // 1. Verificar se o usuário possui os NFTs
    const nftContract = new ethers.Contract(nftContractAddress, nftABI, signer);
    const userAddress = await signer.getAddress();
    const balance = await nftContract.balanceOf(userAddress, nftId);
    
    if (balance.lt(amount)) {
      alert(`You don't have enough NFTs. Your balance: ${balance.toString()}`);
      return;
    }
    
    // 2. Verificar se o contrato já tem aprovação
    const isApproved = await nftContract.isApprovedForAll(userAddress, contractAddress);
    
    // 3. Se não tiver aprovação, solicitar
    if (!isApproved) {
      console.log("Requesting NFT approval...");
      alert("You need to approve the contract to transfer your NFTs first.");
      
      // Esta linha vai fazer aparecer a janela de assinatura para aprovação
      const approveTx = await nftContract.setApprovalForAll(contractAddress, true);
      console.log("Approval transaction sent:", approveTx.hash);
      
      alert("Waiting for approval confirmation...");
      await approveTx.wait();
      alert("NFT approval confirmed! Now staking your NFTs...");
    }
    
    // 4. Agora com aprovação, tentar fazer o stake
    const contract = new ethers.Contract(contractAddress, contractABI, signer);
    console.log("Sending stake transaction...");
    const tx = await contract.stakeNFTs(nftId, parseInt(amount));
    console.log("Stake transaction sent:", tx.hash);
    
    alert("Transaction sent! Waiting for confirmation...");
    await tx.wait();
    
    alert(`NFTs staked successfully. TxHash: ${tx.hash}`);
    await registrarTransacao(tx.hash, "NFTs staked in contract");
    
    alert("After 24 hours, you can register your share to accumulate quotas");
    
  } catch (error) {
    console.error("Erro detalhado:", error);
    alert(formatErrorMessage(error));
  }
};

  const handleProcessar = async () => {
    // Verificar rede antes de executar a ação
    const isCorrectNetwork = await checkAndSwitchNetwork();
    if (!isCorrectNetwork) {
      alert('Please switch to the Polygon network to continue using the application.');
      return;
    }
  
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);
      
      const tx = await contract.payOut();
      await tx.wait();
      
      alert(`Payment processed successfully. TxHash: ${tx.hash}`);
      await registrarTransacao(tx.hash, "Payment processing");
    } catch (error) {
      console.error("Erro detalhado:", error);
      alert(formatErrorMessage(error));
    }
  };
  
  const handleTransfer = async () => {
    // Verificar rede antes de executar a ação
    const isCorrectNetwork = await checkAndSwitchNetwork();
    if (!isCorrectNetwork) {
      alert('Por favor, mude para a rede Polygon para continuar usando o aplicativo.');
      return;
    }
  
    try {
      const response = await fetch('/api/transfer-nft', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ fromAddress: (await window.ethereum.request({ method: 'eth_accounts' }))[0] }),
      });
      if (!response.ok) {
        throw new Error(`Erro na resposta do servidor: ${response.statusText}`);
      }
      const data = await response.json();
      console.log('Resposta do servidor:', data);
      alert(data.message);
      loadTreasuryHistory();
    } catch (error) {
      console.error('Erro ao processar transferência:', error);
      alert(formatErrorMessage(error));
    }
  };

  const handleRecompra = async () => {
    // Substitui a função handleTransfer anterior
    alert("The NFT buyback function will only be available from April 2026.");
  };

  const handleRegistrarCotas = async () => {
    // Verificar rede antes de executar a ação
    const isCorrectNetwork = await checkAndSwitchNetwork();
    if (!isCorrectNetwork) {
      alert('Please switch to the Polygon network to continue using the application.');
      return;
    }
  
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);
      
      const tx = await contract.registerOwnerData();
      await tx.wait();
      
      alert(`Quotas registered successfully. TxHash: ${tx.hash}`);
      await registrarTransacao(tx.hash, "Token earnings request");
    } catch (error) {
      console.error("Erro detalhado:", error);
      alert(formatErrorMessage(error));
    }
  };

  const handleUnstake = async () => {
    // Verificar rede antes de executar a ação
    const isCorrectNetwork = await checkAndSwitchNetwork();
    if (!isCorrectNetwork) {
      alert('Please switch to the Polygon network to continue using the application.');
      return;
    }
  
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);
      
      const tx = await contract.unstakeNFTs();
      await tx.wait();
      
      alert(`NFTs unstaked successfully. TxHash: ${tx.hash}`);
      await registrarTransacao(tx.hash, "NFTs withdrawn from contract");
    } catch (error) {
      console.error("Erro detalhado:", error);
      alert(formatErrorMessage(error));
    }
  };

  // Adicionar uma nova função para processar pagamento e unstake em uma única transação
  const handleProcessarEUnstake = async () => {
    // Verificar rede antes de executar a ação
    const isCorrectNetwork = await checkAndSwitchNetwork();
    if (!isCorrectNetwork) {
      alert('Please switch to the Polygon network to continue using the application.');
      return;
    }
  
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);
      
      console.log("Sending payOutAndUnstake transaction...");
      alert("This operation will process your payment AND unstake your NFTs in a single transaction, saving on gas fees.");
      
      const tx = await contract.payOutAndUnstake();
      console.log("Transaction sent:", tx.hash);
      
      alert("Transaction sent! Waiting for confirmation...");
      await tx.wait();
      
      alert(`Payment and unstake processed successfully in a single transaction. TxHash: ${tx.hash}`);
      await registrarTransacao(tx.hash, "Payment and NFT unstake combined");
    } catch (error) {
      console.error("Erro detalhado:", error);
      alert(formatErrorMessage(error));
    }
  };

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('chainChanged', async (chainId) => {
        // Se mudou para outra rede que não é Polygon, solicita mudar de volta
        if (chainId !== POLYGON_CHAIN_ID && chainId !== MUMBAI_CHAIN_ID) {
          alert('This application only works on the Polygon network. Switching back...');
          await checkAndSwitchNetwork();
        }
      });
    }
  }, []);

  useEffect(() => {
    // Verificar se já está conectado ao carregar a página
    if (window.ethereum) {
      window.ethereum.request({ method: 'eth_accounts' })
        .then(accounts => {
          if (accounts.length > 0) {
            setConnected(true);
            setupAfterConnection();
          }
        })
        .catch(console.error);
    }
  }, []);

  // Adicione este useEffect para verificar a conexão periodicamente se estiver em dispositivo móvel
  useEffect(() => {
    if (isMobile && !connected) {
      // A cada 3 segundos, verificar se o usuário conectou-se
      const checkConnectionInterval = setInterval(async () => {
        if (window.ethereum) {
          try {
            const accounts = await window.ethereum.request({ method: 'eth_accounts' });
            if (accounts.length > 0) {
              setConnected(true);
              await setupAfterConnection();
              clearInterval(checkConnectionInterval);
            }
          } catch (error) {
            console.error("Erro ao verificar conexão:", error);
          }
        }
      }, 3000);
  
      // Limpar o intervalo ao desmontar o componente
      return () => clearInterval(checkConnectionInterval);
    }
  }, [isMobile, connected]);

  // Adicione esta constante no início do componente Dashboard (logo após declarar os estados)
  const treasuryRef = useRef(null);

  // Adicione esta função para rolar até a seção de Tesouraria
  const scrollToTreasury = () => {
    if (treasuryRef.current) {
      treasuryRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (!connected) {
      alert('Please connect your wallet first to access this feature.');
      // Opcional: scroll até o botão de conectar
      document.querySelector('button[onClick="handleConnect"]')?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ backgroundColor: '#0a3dca', minHeight: '100vh' }}>    
        <Menu />
        <Container maxWidth="lg" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
          <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="h4" gutterBottom style={{ margin: 0 }}>
                Dashboard
              </Typography>
              
              <Button 
                variant="contained" 
                color="primary"
                onClick={handleConnect}
                startIcon={connected ? <Avatar sx={{ width: 24, height: 24, bgcolor: '#4caf50' }}>✓</Avatar> : null}
                style={{ minWidth: '160px' }}
              >
                {connected ? "Connected" : "Connect MetaMask"}
              </Button>
            </Box>
            
            {isMobile && !connected && (
              <Paper style={{ padding: '10px', backgroundColor: '#fff9c4', marginTop: '1rem' }}>
                <Typography variant="body2" style={{ color: '#ff8f00' }}>
                On mobile devices, you will be redirected to the MetaMask app. If you don't have the app installed, you will be taken to the app store.
                </Typography>
              </Paper>
            )}
          </Paper>

          {connected && !treasuryHistory.length && (
            <Paper 
              elevation={0}
              style={{ 
                padding: '15px', 
                marginBottom: '20px', 
                backgroundColor: '#e3f2fd', 
                border: '1px solid #bbdefb',
                borderRadius: '8px'
              }}
            >
              <Box display="flex" alignItems="center">
                <span style={{ fontSize: '24px', marginRight: '12px' }}>💡</span>
                <Typography variant="body1">
                  <strong>Getting Started:</strong> Follow the 3-step process above to start earning passive income with your FaucetPool NFTs!
                </Typography>
              </Box>
            </Paper>
          )}

          <Paper elevation={3} style={{ padding: '24px', marginBottom: '20px', background: 'linear-gradient(45deg, #1a237e 0%, #3949ab 100%)' }}>
            <Typography variant="h5" gutterBottom style={{ color: '#ffffff', fontWeight: 600, textAlign: 'center' }}>
              How to Earn with FaucetPool - 3 Simple Steps
            </Typography>
            
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={2} mt={3}>
              {/* Step 1 */}
              <Paper elevation={4} sx={{ 
                flex: 1, 
                padding: 3, 
                position: 'relative',
                border: '2px solid #4caf50',
                height: '100%'
              }}>
                <Box sx={{
                  position: 'absolute',
                  top: -15,
                  left: -15,
                  width: 40,
                  height: 40,
                  borderRadius: '50%',
                  backgroundColor: '#4caf50',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '1.2rem'
                }}>1</Box>
                
                <Typography variant="h6" gutterBottom>
                  Acquire NFT
                </Typography>
                <Typography variant="body2" paragraph sx={{ minHeight: '60px' }}>
                  Buy your FaucetPool NFT to start earning. Each NFT you own gives you access to daily token rewards!
                </Typography>
                
                <Button 
                  variant="contained" 
                  fullWidth
                  style={{ 
                    background: 'linear-gradient(45deg, #4caf50 30%, #81c784 90%)',
                    marginTop: '10px'
                  }}
                  onClick={() => window.open('https://opensea.io/assets/matic/0xb1e65d5db800880b8c81dcfed3770454f3092fcc/1', '_blank')}
                  startIcon={<span>🛒</span>}
                >
                  Buy NFT
                </Button>
              </Paper>
              
              {/* Step 2 */}
              <Paper elevation={4} sx={{ 
                flex: 1, 
                padding: 3, 
                position: 'relative',
                border: '2px solid #ff9800',
                height: '100%'
              }}>
                <Box sx={{
                  position: 'absolute',
                  top: -15,
                  left: -15,
                  width: 40,
                  height: 40,
                  borderRadius: '50%',
                  backgroundColor: '#ff9800',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '1.2rem'
                }}>2</Box>
                
                <Typography variant="h6" gutterBottom>
                  Request Your Share
                </Typography>
                <Typography variant="body2" paragraph sx={{ minHeight: '60px' }}>
                  Register your NFT to request your share in the token distribution pool. You will need to provide your NFT ID.
                </Typography>
                
                <Button 
                  variant="contained" 
                  fullWidth
                  style={{ 
                    background: 'linear-gradient(45deg, #ff9800 30%, #ffb74d 90%)',
                    marginTop: '10px'
                  }}
                  onClick={connected ? scrollToTreasury : handleConnect}
                  startIcon={<span>💼</span>}
                >
                  {connected ? "Go to Treasury" : "Connect to Request"}
                </Button>
              </Paper>
              
              {/* Step 3 */}
              <Paper elevation={4} sx={{ 
                flex: 1, 
                padding: 3, 
                position: 'relative',
                border: '2px solid #2196f3',
                height: '100%'
              }}>
                <Box sx={{
                  position: 'absolute',
                  top: -15,
                  left: -15,
                  width: 40,
                  height: 40,
                  borderRadius: '50%',
                  backgroundColor: '#2196f3',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '1.2rem'
                }}>3</Box>
                
                <Typography variant="h6" gutterBottom>
                  Withdraw Your Rewards
                </Typography>
                <Typography variant="body2" paragraph sx={{ minHeight: '60px' }}>
                  After 24 hours, withdraw your earned tokens! The longer you keep your NFT, the more you earn.
                </Typography>
                
                <Button 
                  variant="contained" 
                  fullWidth
                  style={{ 
                    background: 'linear-gradient(45deg, #2196f3 30%, #64b5f6 90%)',
                    marginTop: '10px'
                  }}
                  onClick={connected ? scrollToTreasury : handleConnect}
                  startIcon={<span>💰</span>}
                >
                  {connected ? "Go to Treasury" : "Connect to Withdraw"}
                </Button>
              </Paper>
            </Box>
          </Paper>

          {hasToken !== null && (
            <Paper elevation={2} style={{ padding: '16px', marginBottom: '20px' }}>
              <Typography variant="body1" style={{ color: hasToken ? '#4caf50' : '#f44336' }}>
                {hasToken ? "✅ You have the required token." : "❌ You don't have the required token."}
              </Typography>
            </Paper>
          )}
          
          <Box mb={4}>
            <Typography variant="h5" gutterBottom style={{ marginBottom: '20px' }}>
              Your Assets
            </Typography>
            <Grid container spacing={3}>
              {dashboardCards.map((card, index) => (
                <Grid item xs={12} md={6} lg={4} key={index}>
                  <Card 
                    elevation={3} 
                    sx={{
                      height: '100%',
                      transition: 'transform 0.2s, box-shadow 0.2s',
                      '&:hover': {
                        transform: 'translateY(-4px)',
                        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)',
                      }
                    }}
                  >
                    <CardActionArea onClick={() => alert(`Welcome ${card.userAddress}`)}>
                      <CardContent>
                        <Typography variant="h6" color="primary" gutterBottom>{card.title}</Typography>
                        <Typography variant="body2" color="text.secondary">{card.content}</Typography>
                        {card.imageUrl && (
                          <Box mt={2} sx={{ overflow: 'hidden', borderRadius: 1 }}>
                            <img 
                              src={card.imageUrl} 
                              alt={card.title} 
                              style={{ 
                                width: '100%', 
                                height: 'auto',
                                transition: 'transform 0.4s',
                                '&:hover': {
                                  transform: 'scale(1.05)',
                                } 
                              }} 
                            />
                          </Box>
                        )}
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Paper 
            elevation={3}
            style={{ padding: '24px', marginTop: '2rem', backgroundColor: '#f9f9f9' }}
            ref={treasuryRef}  // Adicione esta referência aqui
          >
            <Typography variant="h5" gutterBottom style={{ color: '#333', fontWeight: 500 }}>
              Treasury
            </Typography>
            <Divider style={{ margin: '16px 0' }} />
            
            <Box mb={3}>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                Quick Actions:
              </Typography>
              <Box display="flex" gap={2} flexWrap="wrap">
                <Button 
                  variant="contained" 
                  style={{ 
                    background: 'linear-gradient(45deg, #4caf50 30%, #81c784 90%)',
                  }}
                  onClick={() => window.open('https://opensea.io/assets/matic/0xb1e65d5db800880b8c81dcfed3770454f3092fcc/1', '_blank')}
                  startIcon={<span>🛒</span>}
                >
                  Step 1: Buy NFT
                </Button>
                <Button 
                  variant="contained" 
                  style={{ 
                    background: 'linear-gradient(45deg, #ff9800 30%, #ffb74d 90%)',
                  }}
                  onClick={handleSolicitar}
                  startIcon={<span>🔒</span>}
                >
                  Step 2: Stake NFTs
                </Button>
                <Button 
                  variant="contained" 
                  style={{ 
                    background: 'linear-gradient(45deg, #e91e63 30%, #f48fb1 90%)',
                  }}
                  onClick={handleRegistrarCotas}
                  startIcon={<span>💼</span>}
                >
                  Step 3: Register Quotas
                </Button>
                <Button 
                  variant="contained" 
                  style={{ 
                    background: 'linear-gradient(45deg, #2196f3 30%, #64b5f6 90%)',
                  }}
                  onClick={handleProcessarEUnstake}
                  startIcon={<span>💰🔓</span>}
                >
                  Step 4: Payment + Unstake (Save Gas)
                </Button>
                {/* Manter os botões individuais para casos específicos */}
                <Button 
                  variant="outlined" 
                  style={{ 
                    background: 'linear-gradient(45deg, #2196f3 30%, #64b5f6 90%)',
                    opacity: 0.7
                  }}
                  onClick={handleProcessar}
                  startIcon={<span>💰</span>}
                >
                  Process Payment Only
                </Button>
                <Button 
                  variant="outlined" 
                  style={{ 
                    background: 'linear-gradient(45deg, #673ab7 30%, #9575cd 90%)',
                    opacity: 0.7
                  }}
                  onClick={handleUnstake}
                  startIcon={<span>🔓</span>}
                >
                  Unstake Only
                </Button>
                <Button 
                  variant="outlined" 
                  style={{ 
                    borderColor: '#9e9e9e',
                    color: '#757575'
                  }}
                  onClick={handleRecompra}
                  startIcon={<span>🔄</span>}
                >
                  NFT Buyback
                </Button>
              </Box>
            </Box>
            
            <Box mt={4}>
              <Typography variant="h6" gutterBottom>
                Transaction History
              </Typography>
              {treasuryHistory.length > 0 ? (
                <List>
                  {treasuryHistory.map((entry, index) => (
                    <React.Fragment key={index}>
                      <ListItem 
                        style={{ 
                          padding: '16px', 
                          marginBottom: '8px',
                          backgroundColor: 'rgba(255, 255, 255, 0.9)',
                          borderLeft: '4px solid #ffc107',
                          borderRadius: '4px'
                        }}
                      >
                        <ListItemText 
                          primary={
                            <Typography style={{ fontWeight: 'bold', color: '#333' }}>
                              {entry.description}
                              <Chip 
                                size="small" 
                                label="Completed" 
                                color="success" 
                                variant="outlined"
                                style={{ marginLeft: '12px' }} 
                              />
                            </Typography>
                          }
                          secondary={
                            <Box mt={1}>
                              <Typography variant="body2" color="text.secondary">
                                Date: {entry.date}
                              </Typography>
                              {entry.txHash && (
                                <Typography variant="body2" color="text.secondary" style={{ wordBreak: 'break-all' }}>
                                  TxHash: <a href={`https://polygonscan.com/tx/${entry.txHash}`} target="_blank" rel="noopener noreferrer">{entry.txHash}</a>
                                </Typography>
                              )}
                            </Box>
                          }
                        />
                      </ListItem>
                    </React.Fragment>
                  ))}
                </List>
              ) : (
                <Box textAlign="center" py={4}>
                  <Typography color="text.secondary">No transactions found.</Typography>
                </Box>
              )}
            </Box>
          </Paper>
          
          {!connected && (
            <Box 
              display="flex" 
              flexDirection="column" 
              alignItems="center" 
              justifyContent="center"
              mt={10}
              p={5}
              bgcolor="background.paper"
              borderRadius={2}
              boxShadow={3}
            >
              <Typography variant="h5" gutterBottom align="center">
                Connect your wallet to view your assets
              </Typography>
              <Box mt={3}>
                <Button 
                  variant="contained" 
                  color="primary" 
                  size="large"
                  onClick={handleConnect}
                >
                  Connect Wallet
                </Button>
              </Box>
            </Box>
          )}
        </Container>
      </div>
    </ThemeProvider>
  );
};

export default Dashboard;
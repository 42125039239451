import React, { useState } from 'react';
import './OpeningPage.css'; // Uses the base styles from OpeningPage
import Menu from './utils/Menu';
import './Faq.css'; // Specific styles for FAQ
import imagem_FAQ from './img/image_fx_C02.jpg';

const faqData = [
  { question: 'How to buy the NFT?', answer: 'You can purchase our NFT by clicking the "Buy NFT" button in the navigation menu at the top of any page. This will take you to our official collection on OpenSea where you can securely purchase the Season 1 NFT.' },
  { question: 'What is FaucetPool?', answer: 'FaucetPool is a Web3 platform that distributes cryptocurrencies for free through a Faucet and NFT system.' },
  { question: 'How can I earn cryptocurrencies on FaucetPool?', answer: 'You can earn cryptocurrencies through traditional Faucet, NFT Faucet, Cryptocurrency Pool as NFT, the website’s Cryptocurrency, franchise through NFT, and mining the website’s Cryptocurrency.' },
  { question: 'What is an NFT Faucet?', answer: 'An NFT Faucet is a non-fungible token that allows you to periodically redeem cryptocurrencies.' },
  { question: 'How does the Cryptocurrency Pool as NFT work?', answer: 'The Cryptocurrency Pool allows users to participate in a liquidity system and receive rewards proportional to their participation.' },
  { question: 'Are there any fees to use FaucetPool?', answer: 'There are no fees to redeem earnings on FaucetPool, but blockchain transaction fees may apply.' },
  { question: 'Does FaucetPool store my personal data?', answer: 'No, we only register your wallet address and the ownership of our NFTs. No personal data is stored.' },
  { question: 'How does the guaranteed buyback of NFTs work?', answer: 'The buyback occurs according to the established rules, such as the NFT Faucet, which can be bought back 365 days after acquisition.' },
  { question: 'Which blockchain networks are supported by FaucetPool?', answer: 'Currently, FaucetPool operates on networks compatible with Ethereum (EVM) smart contracts.' },
  { question: 'Do I need a digital wallet to use FaucetPool?', answer: 'Yes, you need a compatible digital wallet to interact with the platform’s smart contracts.' },
  { question: 'How can I withdraw my rewards?', answer: 'You can withdraw your rewards using the withdrawal function on the website, according to the smart contract rules.' },
  { question: 'Can I sell my NFTs outside FaucetPool?', answer: 'Yes, NFTs can be traded on marketplaces compatible with the blockchain used.' },
  { question: 'Is there a waiting period to redeem rewards?', answer: 'Yes, there is a minimum period of 24 hours between registrations and withdrawals to prevent abuse.' },
  { question: 'Is FaucetPool secure?', answer: 'Yes, the platform is based on auditable and transparent smart contracts on the blockchain.' },
  { question: 'What happens if I lose access to my wallet?', answer: 'If you lose access to your wallet, you will also lose the NFTs and earnings associated with it. We recommend making proper backups.' },
  { question: 'Does FaucetPool have its own token?', answer: 'Yes, FaucetPool has its own cryptocurrency, which can be mined through specific NFTs.' },
  { question: 'How does the Pagadora.sol smart contract work?', answer: 'The Pagadora.sol contract distributes ERC-20 tokens proportionally to ERC-1155 NFT holders, ensuring a fair reward system.' },
  { question: 'What are the rules of the NFT franchise system?', answer: 'The NFT franchise system allows holders to participate in FaucetPool’s business model and earn proportional income.' },
  { question: 'Does FaucetPool have a roadmap?', answer: 'Yes, FaucetPool has a continuous development roadmap with planned improvements and new features.' },
  { question: 'Does FaucetPool offer customer support?', answer: 'Yes, we offer support through the platform’s official channels.' },
  { question: 'What are the technical requirements to access FaucetPool?', answer: 'You need a Web3-compatible browser and a digital wallet to interact with the smart contracts.' },
  { question: 'Can I mine FaucetPool’s cryptocurrency?', answer: 'Yes, mining is possible through specific NFTs, which allow the generation of new units of the cryptocurrency.' }
];

const FAQPage = () => {
  const [search, setSearch] = useState('');
  const [expandedIndex, setExpandedIndex] = useState(null);
  
  const filteredFAQ = faqData.filter(item =>
    item.question.toLowerCase().includes(search.toLowerCase()) ||
    item.answer.toLowerCase().includes(search.toLowerCase())
  );

  const toggleQuestion = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div className="opening-page">
      <Menu />
      <div className="content faq-content">
      <img src={imagem_FAQ} alt="image_FAQ" className="image" style={{ width: '45%', margin: '0 auto', display: 'block' }} />
        <h1>FAQ - Frequently Asked Questions</h1>
        <div className="search-container">
          <input
            type="text"
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="search-input"
          />
        </div>
        <div className="faq-list">
          {filteredFAQ.map((item, index) => (
            <div 
              key={index} 
              className={`faq-item ${expandedIndex === index ? 'expanded' : ''}`}
              onClick={() => toggleQuestion(index)}
            >
              <div className="faq-question">
                <h2>{item.question}</h2>
                <span className="expand-icon">{expandedIndex === index ? '−' : '+'}</span>
              </div>
              {expandedIndex === index && (
                <div className="faq-answer">
                  <p>{item.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQPage;

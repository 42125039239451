import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import './styles.css';
import OpeningPage from './OpeningPage';
import ProjectInfo from './ProjectInfo';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import Watermark from './utils/Watermark';
import Footer from './utils/Footer'; 
import Nft_01 from './nfts/Nft_01'; 
import Dashboard from './Dashboard'; 
import Loteria from './Loteria';
import Faq from './Faq';
import RoadmapPage from './RoadmapPage';

function App() {
  return (
    <div className="App">
      {/*<Watermark />*/}
      <div className="routes-container">
        <Routes>
          <Route path="/" element={<OpeningPage />} />
          <Route path="/project-info" element={<ProjectInfo />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/nft-01" element={<Nft_01 />} /> 
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/loteria" element={<Loteria />} />
          <Route path="/Faq" element={<Faq />} />
          <Route path="/RoadmapPage" element={<RoadmapPage />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
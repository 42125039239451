import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Container, Typography, IconButton, SvgIcon } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
// Removed TwitterIcon import
// import TwitterIcon from '@mui/icons-material/Twitter';
import './Footer.css';

// Custom component for X icon (formerly Twitter)
const XIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
  </SvgIcon>
);

const Footer = () => {
  return (
    <Box className="global-footer">
      <Container maxWidth="lg">
        <Box className="footer-content">
          <Box className="footer-left">
            <Typography variant="body2">
              © 2025 FaucetPool - NFTs with real utility
            </Typography>
          </Box>
          
          <Box className="footer-center">
            <Link to="/terms-of-service" className="footer-link">Terms of Use</Link>
            <Link to="/privacy-policy" className="footer-link">Privacy Policy</Link>
            <Link to="/Faq" className="footer-link">FAQ</Link>
            <Link to="#" className="footer-link">Contact</Link>
          </Box>
          
          <Box className="footer-right">
            <IconButton 
              href="https://www.facebook.com/profile.php?id=61573845191985" 
              target="_blank" 
              aria-label="Facebook"
              className="social-icon"
            >
              <FacebookIcon />
            </IconButton>
            <IconButton 
              href="https://x.com/Alexand56581251" 
              target="_blank" 
              aria-label="X (formerly Twitter)"
              className="social-icon"
            >
              <XIcon />
            </IconButton>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
import React from 'react';
import './OpeningPage.css';
import Menu from './utils/Menu';
import './Roadmap.css'; // Novo arquivo CSS específico para o roadmap
import { 
  Timeline, 
  TimelineItem, 
  TimelineSeparator, 
  TimelineConnector, 
  TimelineContent, 
  TimelineDot, 
  TimelineOppositeContent 
} from '@mui/lab';
import { Paper, Typography, Box } from '@mui/material';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandIcon from '@mui/icons-material/Expand';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import LanguageIcon from '@mui/icons-material/Language';

const roadmapData = [
  { 
    date: 'April 2025', 
    milestone: 'Official launch of FaucetPool with Faucet and Bitcoin Lottery.',
    icon: <RocketLaunchIcon />,
    color: '#ff9800'
  },
  { 
    date: 'July 2025', 
    milestone: 'Addition of new NFTs to the system, expanding earning options.',
    icon: <AddCircleIcon />,
    color: '#4caf50'
  },
  { 
    date: 'October 2025', 
    milestone: 'Expansion of the NFT Liquidity Pool, allowing greater user participation.',
    icon: <ExpandIcon />,
    color: '#2196f3'
  },
  { 
    date: 'January 2026', 
    milestone: 'Launch of FaucetPool cryptocurrency and start of the NFT mining process.',
    icon: <CurrencyBitcoinIcon />,
    color: '#9c27b0'
  },
  { 
    date: 'April 2026', 
    milestone: 'Integration with new blockchain networks to increase project accessibility.',
    icon: <LanguageIcon />,
    color: '#f44336'
  }
];

const RoadmapPage = () => {
  return (
    <div className="opening-page">
      <Menu />
      <div className="content roadmap-content">
        <Typography variant="h2" className="roadmap-title">
          FaucetPool Roadmap
        </Typography>
        <Typography variant="subtitle1" className="roadmap-subtitle">
          Our path to revolutionize the NFT utility ecosystem
        </Typography>
        
        <Box className="timeline-container">
          <Timeline position="alternate">
            {roadmapData.map((item, index) => (
              <TimelineItem key={index}>
                <TimelineOppositeContent>
                  <Typography variant="h6" className="timeline-date">
                    {item.date}
                  </Typography>
                </TimelineOppositeContent>
                
                <TimelineSeparator>
                  <TimelineDot style={{ backgroundColor: item.color }}>
                    {item.icon}
                  </TimelineDot>
                  {index < roadmapData.length - 1 && <TimelineConnector />}
                </TimelineSeparator>
                
                <TimelineContent>
                  <Paper elevation={3} className="timeline-paper">
                    <Typography variant="h6" className="timeline-milestone-title">
                      Phase {index + 1}
                    </Typography>
                    <Typography className="timeline-milestone-text">
                      {item.milestone}
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Box>
        
        <Box className="roadmap-footer">
          <Typography variant="body1">
            This roadmap represents our vision and is subject to adjustments as we grow and evolve with the community.
          </Typography>
        </Box>
      </div>
    </div>
  );
};

export default RoadmapPage;

/* global BigInt */
import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Button, Paper, Grid, TextField, CircularProgress, Alert, Fade, Divider, List, ListItem, ListItemIcon, ListItemText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Chip, Avatar } from '@mui/material';
import Menu from './utils/Menu';
import InfoIcon from '@mui/icons-material/Info';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import PersonIcon from '@mui/icons-material/Person';
import imagem_loteria from './img/loteria.png';

// Importações necessárias - você precisará instalar essas dependências
// npm install elliptic crypto-js
import EC from 'elliptic';
import CryptoJS from 'crypto-js';

const COOLDOWN_TIME = 30 * 60 * 1000; // 30 minutos em milissegundos
const TARGET_ADDRESS = '1MVDYgVaSN6iKKEsbzRUAYFrYJadLYZvvZ';

const Loteria = () => {
  // Estados existentes
  const [ticket, setTicket] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [consoleOutput, setConsoleOutput] = useState('');
  const [gameResult, setGameResult] = useState(null);
  const [showResult, setShowResult] = useState(false);
  const [cooldownRemaining, setCooldownRemaining] = useState(0);
  const [showExplanation, setShowExplanation] = useState(false);
  
  // Estados para o formulário de carteira Polygon
  const [polygonAddress, setPolygonAddress] = useState('');
  const [addressError, setAddressError] = useState('');
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  
  // Novos estados para o sistema de ranking
  const [ranking, setRanking] = useState([]);
  const [isLoadingRanking, setIsLoadingRanking] = useState(false);
  const [userRankData, setUserRankData] = useState(null);
  const [rankingStats, setRankingStats] = useState({ totalParticipants: 0, totalGames: 0 });

  // Validar endereço Ethereum/Polygon
  const validatePolygonAddress = (address) => {
    const ethAddressRegex = /^0x[a-fA-F0-9]{40}$/;
    return ethAddressRegex.test(address);
  };

  // Função para lidar com a mudança do endereço da carteira
  const handlePolygonAddressChange = (event) => {
    const address = event.target.value;
    setPolygonAddress(address);
    
    if (address && !validatePolygonAddress(address)) {
      setAddressError('Invalid Polygon wallet address');
    } else {
      setAddressError('');
    }
  };

  // Função para enviar a solicitação de NFT
  const handleRequestNFT = async () => {
    if (!validatePolygonAddress(polygonAddress)) {
      setAddressError('Please enter a valid Polygon wallet address');
      return;
    }
    
    setIsRegistering(true);
    
    try {
      const response = await fetch('/api/registrar-participacao-faucet', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          address: polygonAddress,
          lastKey: gameResult?.lastKey || null,
          isNewGame: false // Not a game, just initial registration
        })
      });
      
      const data = await response.json();
      
      if (response.ok) {
        setRegistrationSuccess(true);
        log('Address successfully registered! Play to earn points!');
        
        // Atualizar dados do usuário
        setUserRankData({
          address: polygonAddress,
          points: data.points || 0,
          position: data.position || '-',
          games: 0
        });
        
        // Recarregar ranking
        loadRanking();
      } else {
        setAddressError(data.error || 'An error occurred while registering your participation');
      }
    } catch (error) {
      console.error('Error:', error);
      setAddressError('Connection error. Please try again later.');
    } finally {
      setIsRegistering(false);
    }
  };

  // Verificar se o usuário está em cooldown ao carregar o componente
  useEffect(() => {
    const lastPlayTime = localStorage.getItem('lastLoteriaPlay');
    if (lastPlayTime) {
      const timeElapsed = Date.now() - parseInt(lastPlayTime);
      const remainingTime = COOLDOWN_TIME - timeElapsed;
      
      if (remainingTime > 0) {
        setCooldownRemaining(remainingTime);
      }
    }
  }, []);
  
  // Atualizar o contador de cooldown a cada segundo
  useEffect(() => {
    if (cooldownRemaining <= 0) return;
    
    const timer = setInterval(() => {
      setCooldownRemaining(prev => {
        if (prev <= 1000) {
          clearInterval(timer);
          return 0;
        }
        return prev - 1000;
      });
    }, 1000);
    
    return () => clearInterval(timer);
  }, [cooldownRemaining]);
  
  // Função para formatar o tempo restante
  const formatTimeRemaining = (ms) => {
    const minutes = Math.floor(ms / 60000);
    const seconds = Math.floor((ms % 60000) / 1000);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  // Função original substituída pelo jogo de loteria
  const handlePlayGame = () => {
    // Verificar se está em cooldown
    if (cooldownRemaining > 0) {
      return;
    }
    
    setIsPlaying(true);
    setConsoleOutput('');
    setGameResult(null);
    setShowResult(false);
    setTimeout(loop, 100);
  };

  // Reset do jogo
  const handleReset = () => {
    setShowResult(false);
    setGameResult(null);
  };

  // Função chamada quando o jogo termina
  const finishGame = (result) => {
    setGameResult(result);
    setShowResult(true);
    setIsPlaying(false);
    
    // Definir o timestamp da última jogada
    localStorage.setItem('lastLoteriaPlay', Date.now().toString());
    // Iniciar o tempo de cooldown
    setCooldownRemaining(COOLDOWN_TIME);
    
    // Registrar participação para pontuação
    if (polygonAddress && validatePolygonAddress(polygonAddress)) {
      registerGameParticipation(result.lastKey);
    } else {
      log('Register your Polygon wallet address to earn points and compete for NFTs!');
    }
  };

  const log = (message) => {
    setConsoleOutput(prev => prev + message + '\n');
  };

  function clearLog() {
    setConsoleOutput('');
  }

  function generateRandomNumber(min, max) {
    const range = BigInt(max) - BigInt(min);
    const randomBytes = new Uint8Array(32);
    window.crypto.getRandomValues(randomBytes);

    let hexString = "";
    for (let i = 0; i < randomBytes.length; i++) {
      hexString += randomBytes[i].toString(16).padStart(2, "0");
    }

    const randomBigInt = BigInt(`0x${hexString}`);
    const result = BigInt(min) + (randomBigInt % range);

    return result;
  }

  function hexToBase58(hex) {
    const alphabet = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';
    const base = BigInt(58);
    let num = BigInt('0x' + hex);
    let encoded = '';
    
    while (num > 0) {
      const remainder = num % base;
      num = num / base;
      encoded = alphabet[Number(remainder)] + encoded;
    }

    // Handle leading zeros
    for (let i = 0; i < hex.length && hex[i] === '0'; i += 2) {
      encoded = '1' + encoded;
    }

    return encoded;
  }

  function generateAddress(privateKeyInt) {
    const ec = new EC.ec('secp256k1');
    const keyPair = ec.keyFromPrivate(privateKeyInt, 'hex');
    const publicKey = keyPair.getPublic(true, 'hex'); // Compressed public key

    // Hash the public key with SHA-256
    const sha256Hash = CryptoJS.SHA256(CryptoJS.enc.Hex.parse(publicKey));
    // Hash the result with RIPEMD-160
    const ripemd160Hash = CryptoJS.RIPEMD160(sha256Hash);

    // Add version byte (0x00 for Main Network)
    const version = '00';
    const versionedHash = version + ripemd160Hash.toString();

    // Double SHA-256 hash the versioned hash and take the first 4 bytes for the checksum
    const checksum = CryptoJS.SHA256(CryptoJS.SHA256(CryptoJS.enc.Hex.parse(versionedHash))).toString().substring(0, 8);

    // Combine the versioned hash and checksum
    const addressHex = versionedHash + checksum;

    // Convert the result to Base58
    const address = hexToBase58(addressHex);

    return address;
  }

  function loop() {
    // Carteira 68 (atualizado da carteira 67)
    let privateKeyInt = generateRandomNumber(0x80000000000000000, 0xBfffffffffffffff);
    console.log('Chave de início: ', privateKeyInt.toString(16));
    log('Starting search with key: ' + privateKeyInt.toString(16)); // Traduzido de "Iniciando busca com a chave"
    
    let found = false;
    
    // Usando um timeout para não bloquear a UI
    const checkBatch = (i) => {
      const batchSize = 100;
      const endIndex = Math.min(i + batchSize, 50000);
      
      for (let j = i; j < endIndex; j++) {
        const address = generateAddress(privateKeyInt.toString(16));
        
        // Carteira alvo
        if (address === TARGET_ADDRESS) {
          log('Found it: ' + privateKeyInt.toString(16)); // Traduzido de "Achei"
          found = true;
          // Mostrar resultado de sucesso
          finishGame({
            success: true,
            message: 'CONGRATULATIONS! You found the private key!', // Traduzido de "PARABÉNS! Você encontrou a chave privada!"
            privateKey: privateKeyInt.toString(16),
            address: TARGET_ADDRESS
          });
          return;
        }
        privateKeyInt++;
      }
      
      if (i < 50000 && !found) {
        // Atualiza o progresso a cada lote
        if (i % 1000 === 0) {
          log(`Checking... ${i} keys checked`); // Traduzido de "Verificando..."
        }
        setTimeout(() => checkBatch(endIndex), 0);
      } else if (!found) {
        log('Nothing found :( Don\'t give up, try again for free anytime'); // Traduzido de "Não encontrei nada..."
        log('Last key checked: ' + privateKeyInt.toString(16)); // Traduzido de "Última chave checada"
        // Mostrar resultado de falha
        finishGame({
          success: false,
          message: 'We didn\'t find the key this time. Try again!', // Traduzido de "Não encontramos a chave desta vez..."
          lastKey: privateKeyInt.toString(16)
        });
      }
    };
    
    checkBatch(0);
  }

  // Função para carregar o ranking
  const loadRanking = async () => {
    setIsLoadingRanking(true);
    try {
      const response = await fetch('/api/ranking-faucet');
      const data = await response.json();
      
      if (response.ok) {
        setRanking(data.ranking || []);
        setRankingStats(data.stats || { totalParticipants: 0, totalGames: 0 });
        
        // Se temos um endereço de carteira, encontre os dados do usuário no ranking
        if (polygonAddress) {
          const userData = data.ranking.find(item => item.address.toLowerCase() === polygonAddress.toLowerCase());
          if (userData) {
            setUserRankData(userData);
          }
        }
      }
    } catch (error) {
      console.error("Error loading ranking:", error);
    } finally {
      setIsLoadingRanking(false);
    }
  };
  
  // Carregar o ranking ao montar o componente e quando o endereço da carteira mudar
  useEffect(() => {
    loadRanking();
    // Buscar ranking a cada 60 segundos
    const interval = setInterval(loadRanking, 60000);
    return () => clearInterval(interval);
  }, [polygonAddress]);

  // Função para registrar a participação após cada jogo
  const registerGameParticipation = async (lastKey) => {
    // Se não tiver endereço de carteira registrado, não registre pontuação
    if (!polygonAddress || !validatePolygonAddress(polygonAddress)) {
      return;
    }
    
    try {
      const response = await fetch('/api/registrar-participacao-faucet', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          address: polygonAddress,
          lastKey: lastKey || null,
          isNewGame: true // Indicar que este é um novo jogo
        })
      });
      
      const data = await response.json();
      
      if (response.ok) {
        // Atualizar dados do usuário
        setUserRankData({
          address: polygonAddress,
          points: data.points,
          position: data.position,
          games: (userRankData?.games || 0) + 1
        });
        
        log(`Score updated! Points: ${data.points} - Ranking position: ${data.position}`); // Traduzido de "Pontuação atualizada!"
        
        // Se estiver entre os 100 primeiros, mostrar mensagem especial
        if (data.willReceiveNFT) {
          log(`Congratulations! You're in the top 100 and will receive an NFT!`); // Traduzido de "Parabéns! Você está entre os 100 primeiros..."
        }
        
        // Recarregar ranking
        loadRanking();
      }
    } catch (error) {
      console.error("Erro ao registrar participação no jogo:", error);
    }
  };

  // Função para truncar endereço longo
  const truncateAddress = (address) => {
    return address.slice(0, 6) + '...' + address.slice(-4);
  };

  return (
    <div>
      <Menu />
      <Container maxWidth="md">
        <Box my={4}>
          {/* Ad script placement */}
          <div
            dangerouslySetInnerHTML={{
              __html: `
                <script async type="application/javascript" src="https://a.magsrv.com/ad-provider.js"></script>
                <ins class="eas6a97888e2" data-zoneid="5566376"></ins>
                <script>(AdProvider = window.AdProvider || []).push({"serve": {}})</script>
              `
            }}
          />
          
          {/* Small spacing between ad and content */}
          <Box mt={2}></Box>
          
          <Typography variant="h3" component="h1" gutterBottom align="center">
            Free Bitcoin Lottery
          </Typography>
          <img src={imagem_loteria} alt="lottery_image" className="image" style={{ width: '45%', margin: '0 auto', display: 'block' }} />

          {/* Formulário de participação na faucet - Atualizado para o sistema de ranking */}
          <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
            <Box display="flex" alignItems="center" mb={2}>
              <MilitaryTechIcon sx={{ color: 'primary.main', mr: 1, fontSize: 28 }} />
              <Typography variant="h5">
                NFT Season 1 - Ranking System
              </Typography>
            </Box>
            
            <Typography paragraph>
              Play the lottery with your registered Polygon wallet address to earn points! 
              The <strong>top 100 players</strong> in the ranking will receive an exclusive Season 1 NFT.
            </Typography>
            
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={8}>
                <TextField
                  label="Polygon Wallet Address"
                  variant="outlined"
                  fullWidth
                  placeholder="0x..."
                  value={polygonAddress}
                  onChange={handlePolygonAddressChange}
                  error={!!addressError}
                  helperText={addressError}
                  disabled={isRegistering || registrationSuccess}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleRequestNFT}
                  disabled={isRegistering || !polygonAddress || !!addressError || registrationSuccess}
                >
                  {isRegistering ? (
                    <>
                      <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                      Sending...
                    </>
                  ) : 'Register Address'}
                </Button>
              </Grid>
            </Grid>
            
            {registrationSuccess && (
              <Alert severity="success" sx={{ mt: 2 }}>
                Your address has been registered! Play the lottery to earn points and climb the ranking.
              </Alert>
            )}
            
            {userRankData && (
              <Box sx={{ mt: 2, p: 2, bgcolor: 'background.paper', borderRadius: 1, border: '1px solid #e0e0e0' }}>
                <Typography variant="subtitle1" gutterBottom>
                  Your Ranking Data:
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2" color="text.secondary">Points:</Typography>
                    <Typography variant="h6" color="primary.main">{userRankData.points}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" color="text.secondary">Position:</Typography>
                    <Typography variant="h6" color="primary.main">#{userRankData.position}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" color="text.secondary">Games:</Typography>
                    <Typography variant="h6" color="primary.main">{userRankData.games}</Typography>
                  </Grid>
                </Grid>
              </Box>
            )}
            
            <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
              * Each completed game will give 10 points to your registered address.
            </Typography>
          </Paper>

          <Paper elevation={3} sx={{ p: 4, mt: 4, position: 'relative' }}>
            {/* Efeito matrix quando estiver jogando */}
            {isPlaying && (
              <Box 
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0,0,0,0.7)',
                  zIndex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#0f0',
                  fontFamily: 'Courier New, monospace',
                  overflow: 'auto',
                  padding: 3
                }}
              >
                <Typography variant="h6" component="div" sx={{ color: '#0f0', mb: 2 }}>
                  Web Lottery 0.1 - Searching...
                </Typography>
                <CircularProgress color="success" sx={{ mb: 2 }} />
                <Box 
                  sx={{
                    whiteSpace: 'pre-line',
                    width: '100%',
                    maxHeight: '200px',
                    overflow: 'auto',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    padding: 2,
                    borderRadius: 1
                  }}
                >
                  {consoleOutput}
                </Box>
              </Box>
            )}
            
            {/* Mensagem de resultado */}
            <Fade in={showResult}>
              <Box sx={{ mb: 3 }}>
                {gameResult && (
                  <Alert 
                    severity={gameResult.success ? "success" : "info"}
                    onClose={handleReset}
                    sx={{ mb: 2 }}
                  >
                    <Typography variant="h6">{gameResult.message}</Typography>
                    {gameResult.success ? (
                      <Typography>Key found: {gameResult.privateKey}</Typography>
                    ) : (
                      <Typography>Last key checked: {gameResult.lastKey}</Typography>
                    )}
                  </Alert>
                )}
              </Box>
            </Fade>
            
            <Typography variant="h5" gutterBottom>
              Bitcoin Cryptography Challenge
            </Typography>
            
            <Typography paragraph>
              Participate in our challenge and try to find the private key of the Bitcoin wallet 
              <Box component="span" sx={{ fontWeight: 'bold', mx: 1 }}>{TARGET_ADDRESS}</Box>! 
              Each attempt generates and checks 50,000 random keys.
            </Typography>
            
            <Button 
              color="info" 
              sx={{ mb: 2 }} 
              onClick={() => setShowExplanation(!showExplanation)}
            >
              {showExplanation ? 'Hide explanation' : 'What is this challenge?'}
            </Button>
            
            {showExplanation && (
              <Paper elevation={1} sx={{ p: 2, mb: 3, bgcolor: 'info.50' }}>
                <Typography variant="h6" gutterBottom sx={{ color: 'info.main' }}>
                  About the Bitcoin Challenge
                </Typography>
                
                <Typography paragraph>
                  This is a cryptographic challenge that simulates the search for a specific Bitcoin 
                  "private key". Each Bitcoin address is generated from a unique private key - a 64-character 
                  hexadecimal number.
                </Typography>
                
                <Typography paragraph>
                  The space of possible private keys is astronomically large (2^256), making it virtually 
                  impossible to find a specific key by pure trial and error. This challenge is an educational 
                  demonstration of that security.
                </Typography>
                
                <List dense>
                  <ListItem>
                    <ListItemIcon><InfoIcon color="info" /></ListItemIcon>
                    <ListItemText primary="The target address is real, but the chance of finding it is extremely small" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon><InfoIcon color="info" /></ListItemIcon>
                    <ListItemText primary="If you actually find the key (an extremely rare event), follow the instructions carefully" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon><InfoIcon color="info" /></ListItemIcon>
                    <ListItemText primary="Each attempt checks 50,000 different random keys" />
                  </ListItem>
                </List>
              </Paper>
            )}
            
            <Button 
              variant="contained" 
              color="success" 
              fullWidth 
              sx={{ mt: 2, py: 1.5, fontSize: '1.2rem' }}
              onClick={handlePlayGame}
              disabled={isPlaying || cooldownRemaining > 0}
            >
              {isPlaying 
                ? 'Searching...' 
                : cooldownRemaining > 0 
                  ? `Wait ${formatTimeRemaining(cooldownRemaining)} to play again` 
                  : 'Start Search'}
            </Button>

            {cooldownRemaining > 0 && (
              <Typography 
                variant="body2" 
                color="text.secondary" 
                align="center" 
                sx={{ mt: 1 }}
              >
                New attempt available in {formatTimeRemaining(cooldownRemaining)}
              </Typography>
            )}
          </Paper>
          
          {/* Papel especial que só aparece em caso de sucesso */}
          <Fade 
            in={showResult && gameResult && gameResult.success}
            style={{ 
              display: (showResult && gameResult && gameResult.success) ? 'block' : 'none',
              marginTop: 2 
            }}
          >
            <Paper elevation={5} sx={{ p: 3, border: '2px solid #ffc107' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <EmojiEventsIcon sx={{ color: 'warning.main', fontSize: 40, mr: 2 }} />
                <Typography variant="h4" color="warning.dark">
                  YOU FOUND THE KEY!
                </Typography>
              </Box>
              
              <Alert severity="warning" sx={{ mb: 3 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  ATTENTION: KEEP THIS INFORMATION SECURE!
                </Typography>
              </Alert>
              
              <Box sx={{ bgcolor: '#f8f9fa', p: 2, borderRadius: 1, mb: 3 }}>
                <Typography variant="subtitle1" gutterBottom>
                  <VpnKeyIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                  Private Key Found:
                </Typography>
                <Typography variant="body1" sx={{ fontFamily: 'monospace', fontWeight: 'bold' }}>
                  {gameResult?.privateKey}
                </Typography>
                
                <Divider sx={{ my: 2 }} />
                
                <Typography variant="subtitle1" gutterBottom>
                  Bitcoin Address:
                </Typography>
                <Typography variant="body1" sx={{ fontFamily: 'monospace', fontWeight: 'bold' }}>
                  {gameResult?.address}
                </Typography>
              </Box>
              
              <Typography variant="h6" gutterBottom>
                What to do now:
              </Typography>
              
              <List>
                <ListItem>
                  <ListItemIcon><InfoIcon color="warning" /></ListItemIcon>
                  <ListItemText 
                    primary="COPY and SAVE the private key in a secure place immediately!" 
                    secondary="This screen will not be shown again"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon><InfoIcon color="warning" /></ListItemIcon>
                  <ListItemText 
                    primary="Contact our administration" 
                    secondary="Send an email to cascecim@gmail.com with the subject 'Bitcoin Lottery - Key Found'"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon><InfoIcon color="warning" /></ListItemIcon>
                  <ListItemText 
                    primary="DO NOT share this key with anyone other than our official team" 
                  />
                </ListItem>
              </List>
              
              <Typography variant="body2" color="text.secondary" sx={{ mt: 3 }}>
                Note: This event is extremely rare. Our team will verify the authenticity of the found key.
                If confirmed, you will receive a substantial reward according to the challenge terms.
              </Typography>
            </Paper>
          </Fade>
          
          {/* Banner promocional da Season 1 NFT - Com espaçamento reduzido */}
          <Paper 
            elevation={3} 
            sx={{ 
              p: 3, 
              mt: 2, 
              backgroundImage: 'linear-gradient(to right, #134e5e, #71b280)',
              color: 'white'
            }}
          >
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} md={4}>
                <Box 
                  sx={{ 
                    bgcolor: 'rgba(255,255,255,0.1)', 
                    borderRadius: 2,
                    p: 2,
                    textAlign: 'center',
                    transform: 'rotate(-3deg)',
                    boxShadow: '0 4px 20px rgba(0,0,0,0.2)'
                  }}
                >
                  <EmojiEventsIcon sx={{ fontSize: 60, color: 'warning.light', mb: 1 }} />
                  <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                    NFT SEASON 1
                  </Typography>
                  <Typography variant="h6" sx={{ color: 'warning.light' }}>
                    Exclusive Collectible
                  </Typography>
                </Box>
              </Grid>
              
              <Grid item xs={12} md={8}>
                <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
                  Earn Exclusive NFTs by Participating in the Lottery!
                </Typography>
                
                <List dense>
                  <ListItem>
                    <ListItemIcon>
                      <MilitaryTechIcon sx={{ color: 'warning.light' }} />
                    </ListItemIcon>
                    <ListItemText 
                      primary="NFTs with increasing value in the FaucetPool ecosystem"
                      secondary="Each NFT will be eligible for future drops and exclusive benefits"
                      secondaryTypographyProps={{ color: 'rgba(255,255,255,0.7)' }}
                    />
                  </ListItem>
                  
                  <ListItem>
                    <ListItemIcon>
                      <LeaderboardIcon sx={{ color: 'warning.light' }} />
                    </ListItemIcon>
                    <ListItemText 
                      primary="10 points per game! The top 100 guarantee an exclusive NFT"
                      secondary="The more you play, the better your ranking position"
                      secondaryTypographyProps={{ color: 'rgba(255,255,255,0.7)' }}
                    />
                  </ListItem>
                  
                  <ListItem>
                    <ListItemIcon>
                      <VpnKeyIcon sx={{ color: 'warning.light' }} />
                    </ListItemIcon>
                    <ListItemText 
                      primary="NFTs unlock premium features on the platform"
                      secondary="Early access to new features and reward pools"
                      secondaryTypographyProps={{ color: 'rgba(255,255,255,0.7)' }}
                    />
                  </ListItem>
                </List>
                
                <Button 
                  variant="contained" 
                  color="warning" 
                  size="large" 
                  sx={{ mt: 2, fontWeight: 'bold' }}
                  onClick={handlePlayGame}
                  disabled={isPlaying || cooldownRemaining > 0}
                >
                  {cooldownRemaining > 0 
                    ? `Wait ${formatTimeRemaining(cooldownRemaining)}` 
                    : 'PLAY AND EARN POINTS!'}
                </Button>
              </Grid>
            </Grid>
          </Paper>
          
          {/* Combinar estatísticas e ranking em um único Paper para economizar espaço */}
          <Paper elevation={3} sx={{ p: 3, mt: 3 }}> {/* Reduzido de p: 4, mt: 4 para p: 3, mt: 3 */}
            <Grid container spacing={3}>
              {/* Estatísticas do Desafio */}
              <Grid item xs={12} md={4}>
                <Box sx={{ borderRight: {md: '1px solid #e0e0e0'}, pr: {md: 2}, height: '100%' }}>
                  <Box display="flex" alignItems="center" mb={2}>
                    <InfoIcon sx={{ color: 'primary.main', mr: 1, fontSize: 24 }} />
                    <Typography variant="h5" gutterBottom sx={{ mb: 0 }}>
                      Statistics
                    </Typography>
                  </Box>
                  
                  <List dense disablePadding>
                    <ListItem disableGutters>
                      <ListItemText 
                        primary="Global attempts" 
                        secondary="1,423,827"
                        secondaryTypographyProps={{ fontWeight: 'bold' }}
                      />
                    </ListItem>
                    <ListItem disableGutters>
                      <ListItemText 
                        primary="Keys checked" 
                        secondary="71,191,350,000"
                        secondaryTypographyProps={{ fontWeight: 'bold' }}
                      />
                    </ListItem>
                    <ListItem disableGutters>
                      <ListItemText 
                        primary="Status" 
                        secondary="In progress"
                        secondaryTypographyProps={{ fontWeight: 'bold' }}
                      />
                    </ListItem>
                    <ListItem disableGutters>
                      <ListItemText 
                        primary="Participants" 
                        secondary={rankingStats.totalParticipants}
                        secondaryTypographyProps={{ fontWeight: 'bold' }}
                      />
                    </ListItem>
                    <ListItem disableGutters>
                      <ListItemText 
                        primary="Games played" 
                        secondary={rankingStats.totalGames}
                        secondaryTypographyProps={{ fontWeight: 'bold' }}
                      />
                    </ListItem>
                  </List>
                </Box>
              </Grid>
              
              {/* Ranking de Participantes */}
              <Grid item xs={12} md={8}>
                <Box display="flex" alignItems="center" mb={2}>
                  <LeaderboardIcon sx={{ color: 'secondary.main', mr: 1, fontSize: 28 }} />
                  <Typography variant="h5">
                    Participant Ranking - NFT Season 1
                  </Typography>
                </Box>
                
                {isLoadingRanking ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <TableContainer sx={{ maxHeight: 350 }}> {/* Altura reduzida */}
                    <Table size="small" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell width="10%">#</TableCell>
                          <TableCell width="50%">Address</TableCell>
                          <TableCell width="20%" align="center">Points</TableCell>
                          <TableCell width="20%" align="center">Games</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ranking.length === 0 ? (
                          <TableRow>
                            <TableCell colSpan={4} align="center">No participants registered yet</TableCell>
                          </TableRow>
                        ) : (
                          ranking.map((item, index) => {
                            // Verificar se esta linha é o usuário atual
                            const isCurrentUser = polygonAddress && 
                                                 item.address.toLowerCase() === polygonAddress.toLowerCase();
                            
                            // Determinar estilo da linha baseado na posição e se é o usuário atual
                            let rowStyle = {};
                            if (isCurrentUser) {
                              rowStyle = { bgcolor: 'rgba(25, 118, 210, 0.08)', fontWeight: 'bold' };
                            } else if (index < 3) {
                              // Top 3 - cores diferentes
                              const colors = ['gold', 'silver', '#cd7f32'];
                              rowStyle = { bgcolor: `rgba(${index === 0 ? '255, 215, 0' : index === 1 ? '192, 192, 192' : '205, 127, 50'}, 0.1)` };
                            } else if (index < 100) {
                              // Top 100 - elegível para NFT
                              rowStyle = { bgcolor: 'rgba(76, 175, 80, 0.04)' };
                            }
                            
                            return (
                              <TableRow key={item.address} sx={rowStyle} hover>
                                <TableCell>
                                  {index < 3 ? (
                                    <Avatar sx={{ 
                                      width: 24, 
                                      height: 24, 
                                      bgcolor: index === 0 ? 'warning.main' : index === 1 ? 'grey.400' : 'warning.dark',
                                      fontSize: '0.875rem',
                                      fontWeight: 'bold'
                                    }}>
                                      {index + 1}
                                    </Avatar>
                                  ) : (
                                    index + 1
                                  )}
                                </TableCell>
                                <TableCell>
                                  <Tooltip title={item.address}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                      {truncateAddress(item.address)}
                                      {isCurrentUser && (
                                        <Chip 
                                          size="small" 
                                          label="You" 
                                          color="primary" 
                                          variant="outlined"
                                          sx={{ ml: 1 }}
                                        />
                                      )}
                                      {index < 100 && (
                                        <Tooltip title="Eligible for NFT">
                                          <EmojiEventsIcon 
                                            fontSize="small" 
                                            sx={{ ml: 1, color: index < 3 ? 'warning.main' : 'success.main' }} 
                                          />
                                        </Tooltip>
                                      )}
                                    </Box>
                                  </Tooltip>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography fontWeight={index < 10 ? 'bold' : 'normal'}>
                                    {item.points}
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">{item.games}</TableCell>
                              </TableRow>
                            );
                          })
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                
                <Box sx={{ mt: 2 }}>
                  <Alert severity="info" sx={{ py: 1 }}> {/* Padding-y reduzido */}
                    <Typography variant="body2">
                      The top 100 players will receive an exclusive Season 1 NFT!
                    </Typography>
                  </Alert>
                </Box>
              </Grid>
            </Grid>
          </Paper>

          {/* Adicionar conteúdo visual para preencher o espaço */}
          <Paper 
            elevation={2} 
            sx={{ 
              p: 2, 
              mt: 3,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              bgcolor: 'info.50'
            }}
          >
            <Box>
              <Typography variant="h6" color="primary.dark">
                FaucetPool - Project in Development
              </Typography>
              <Typography variant="body2" color="text.secondary">
                We are building the future of token and NFT distribution.
                Stay tuned for upcoming updates and new features!
              </Typography>
            </Box>
            <Button 
              variant="outlined" 
              color="info"
              endIcon={<InfoIcon />}
              href="https://github.com/yourusername/faucetpool"
              target="_blank"
              sx={{ ml: 2 }}
            >
              Learn More
            </Button>
          </Paper>
        </Box>
      </Container>
    </div>
  );
};

export default Loteria;
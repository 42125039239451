import React from 'react';
import './OpeningPage.css'; // Uses the same styles as OpeningPage
import Menu from './utils/Menu';

const TermsOfService = () => {
  return (
    <div className="opening-page">
      <Menu />
      
      <div className="content">
        <h1>Terms of Service</h1>
        <p>
          Welcome to FaucetPool. These Terms of Service govern the use of the platform, establishing the rights and responsibilities of users. By interacting with FaucetPool, you agree to the terms described below.
        </p>

        <h2>Platform Usage</h2>
        <p>
          FaucetPool offers an NFT-based cryptocurrency distribution system, allowing users to participate in a rewards system and periodic withdrawals.
        </p>
        <ul>
          <li>Own a compatible digital wallet to interact with smart contracts.</li>
          <li>Acquire and maintain specific NFTs to participate in the token distribution system.</li>
          <li>Follow the buyback and withdrawal rules defined by the smart contract.</li>
        </ul>
        
        <h2>Usage Restrictions</h2>
        <p>
          The use of FaucetPool for illegal or fraudulent activities is prohibited, including but not limited to:
        </p>
        <ul>
          <li>Manipulation of the reward system through multiple wallets or other abusive practices.</li>
          <li>Attempts to exploit vulnerabilities in the smart contract.</li>
          <li>Any action that may compromise the security or integrity of FaucetPool.</li>
        </ul>
        
        <h2>Electronic Contract Summary</h2>
        <p>
          FaucetPool uses the <strong>Pagadora.sol</strong> smart contract, which implements a distribution system of ERC-20 tokens for holders of ERC-1155 NFTs. The contract manages reward distribution based on the number of NFTs owned, ensuring transparency and fairness.
        </p>
        
        <h3>Key Features of the Pagadora.sol Contract:</h3>
        <ul>
          <li><strong>Proportional Reward Calculation:</strong> The more NFTs a user owns, the greater their share in the token pool.</li>
          <li><strong>NFT Registration and Quotas:</strong> The system calculates quotas proportionally and imposes a 24-hour waiting period between updates and withdrawals.</li>
          <li><strong>Secure Payments:</strong> Protection against reentrancy attacks and transparency events for auditing.</li>
          <li><strong>Token Transfers:</strong> The contract verifies the available balance before making any payment, ensuring that all transactions are secure.</li>
        </ul>
        
        <h2>Disclaimer</h2>
        <p>
          FaucetPool operates in a decentralized manner, meaning:
        </p>
        <ul>
          <li>We do not store or control user funds.</li>
          <li>All transactions are recorded on the blockchain and cannot be reversed.</li>
          <li>Users are responsible for ensuring the security of their wallets and private keys.</li>
        </ul>
        
        <h2>Changes to Terms</h2>
        <p>
          These Terms of Service may be updated periodically to reflect improvements to the platform or adjustments to system rules. Any changes will be communicated through the official FaucetPool website.
        </p>
        
        <h2>Contact</h2>
        <p>
          For questions or support, please contact us through FaucetPool's official channels.
        </p>
      </div>
    </div>
  );
};

export default TermsOfService;

